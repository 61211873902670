import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AudioBufferHelper from 'models/audioEditor/AudioBufferHelper';
import WAAClock from 'libs/waaclock/WAAClock';
import AudioFilterFactory from 'models/audioEditor/filters/AudioFilterFactory';
import SequencerNodeSchedulingInfo from 'models/audioEditor/SequencerNodeSchedulingInfo';
import MergedSequencerNodeSchedulingInfo from 'models/audioEditor/MergedSequencerNodeSchedulingInfo';
import MergedIncompleteWrapSequencerNodeSchedulingInfo from 'models/audioEditor/MergedIncompleteWrapSequencerNodeSchedulingInfo';
import AudioContextScheduler from 'models/audioEditor/AudioContextScheduler';
import SequencerRendererTracksInfo from 'models/audioEditor/SequencerRendererTracksInfo';

//'libs/webmwriter/webm-writer-0.3.0',
import VideoContext from 'libs/videoContext/videoContext';

import ti18n from 'i18n!nls/Sequenceri18n';
import Utils from 'models/helper/Utils';
import AudioPosition from 'models/audioEditor/AudioPosition';
import RolloutHelper from 'models/helper/FeatureRolloutHelper';
import SequencerRendererInterface from 'models/audioEditor/SequencerRendererInterface';
import RSVP from 'rsvp';
const DEFAULT_MIME_TYPE_FOR_AUDIO_RENDER = 'audio/wav'
const Rollout = RolloutHelper.getInstance()
const TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK = Rollout.isFeatureEnabled(
  Rollout.FEATURES.TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK
)
const SCHEDULING_DELAY_IN_SECONDS_FOR_PLAYBACK = 0.5
const APPLY_SEQUENCER_SETTINGS_AT_PLAYBACK = Rollout.getFeatureVariable(
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables
    .apply_sequencer_settings_at_playback,
  true
)
const TOLERANCE_VALUES_FOR_PLAYBACK_FUNCTION = { early: 0.1, late: 1 }
const HIGH_TOLERANCE_VALUES_FOR_PLAYBACK_FUNCTION = { early: 0.1, late: 10 }
const USE_CANVAS_RECORDING_FOR_VIDEO_RENDERING = false
const RENDER_MERGED_NODE_IN_BACTCH_DURING_LIVE_PLAYBACK = true
const MAX_TIME_TO_SCHEDULE_MERGED_NODES_AHEAD = 5000
const TIME_BEFORE_NEXT_SCHEDULE_PLAYBACK_BATCH = 3000
const USE_FFMPEG_FOR_VIDEO_RENDERING = true
const MAX_NUMBER_OF_VIDEOS_TO_PROCESS_AT_ONCE_VIA_FFMPEG = 10
const RENDER_USING_FFMPEG_OVERLAY = Rollout.getFeatureVariable(
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables
    .render_video_using_ffmpeg_overlay,
  false
)
const SequencerRendererImpl = SequencerRendererInterface.extend({
  idAttribute: 'id',

  _initialize: function () {
    //this._nodeCidToAudioBufferPromise = {};
    this._nodeCidToNodeSchedulingInfo = {}
    //this._nodeCidToAudioBuffer = {};
    this._endNodeCidToDurationInMilliseconds = {}
    this._audioUrlToAudioId = {}
    this._audioIdWithAudioTypeAndSettingToAudioBuffer = {}
    this._startTimeInMillisecondsToNodeSchedulingInfos = {}
    this._totalBufferDurationInMilliseconds = 0
    this._audioContextScheduler = null
    this._finishPlayingClockEvent = null
    this._arrayOfAudioNodesUsed = []
    this._arrayOfVideoNodesUsed = []
    this._clockContextStartTimeOfFirstNodeScheduled = null
    this._clockVideoContextStartTimeOfFirstNodeScheduled = null
    this._firstNodeSchedulingInfo = null
    this._nextBatchOfMergedNodePlaybackRenderingTimeoutId = null
    this._nextMergedSchedulingNodeIndexToRender = null
    this._nodeCidToNextNonRetainedDeletedSequencerNode = {};
    this._nonRetainedDeletedSequencerNodeCidToNextNode = {};
    this._numberOfMergedVideoNodes = null
    this._maxNumberOfChannels = 0
    this.listenTo(
      this.getSequencer().getAudioBufferCache(),
      'audioBufferUnloadedFromCache',
      this._onAudioBufferUnloadedFromCache.bind(this)
    )
    this.listenTo(
      this.getSequencer().getAudioBufferCache(),
      'audioContextClosed',
      this._onAudioContextClosedOnAudioBufferCache.bind(this)
    )
  },
  _createSequencerRendererTracksInfo: function (
    audioContext,
    arrayOfAudioNodesUsed,
    videoContext,
    arrayOfVideoNodesUsed
  ) {
    if (!arrayOfAudioNodesUsed) {
      arrayOfAudioNodesUsed = []
    }

    const sequencerRendererTracksInfo = new SequencerRendererTracksInfo()
    return sequencerRendererTracksInfo.init(
      audioContext,
      this.getSequencer(),
      arrayOfAudioNodesUsed,
      APPLY_SEQUENCER_SETTINGS_AT_PLAYBACK,
      videoContext,
      arrayOfVideoNodesUsed
    )
  },

  _onAudioContextClosedOnAudioBufferCache : function(){
    this.stopListening(this.getSequencer().getAudioBufferCache());
  },

  _onAudioBufferUnloadedFromCache: function (audioBufferId) {
    delete this._audioIdWithAudioTypeAndSettingToAudioBuffer[audioBufferId]
  },

  _getAllTrackNodes: function () {
    return [
      this._masterTrackNode,
      this._voiceTrackNode,
      this._insertTrackNode,
      this._wrapTrackNode,
      this._undefinedTrackNode
    ]
  },

  playPauseRenderedBuffer: function () {
    if (this.isRenderedBufferPlaying()) {
      this.stopPlayingRenderedBuffer()
    } else {
      this.playRenderedBuffer()
    }
  },

  isRenderedBufferPlaying: function () {
    return this.get('renderedBufferIsPlaying')
  },

  _getCurrentlyPlayingSequencerNode: function () {
    return this.get('currentlyPlayingSequencerNode')
  },

  _updateCurrentlyPlayingSequencerNode: function (sequencerNode, startTimeInContext) {
    const currentPlayingSequencerNode = this.get(
      'currentlyPlayingSequencerNode'
    )
    const startTimeOfPlayingNodeInContext = this.get('startTimeOfPlayingNodeInContext');
    if(!sequencerNode || !startTimeOfPlayingNodeInContext ||!startTimeInContext ||  startTimeOfPlayingNodeInContext <= startTimeInContext){
      if (currentPlayingSequencerNode) {
        currentPlayingSequencerNode.setPlaying(false)
      }
      if (sequencerNode) {
        sequencerNode.setPlaying(true)
      }
      this.set('currentlyPlayingSequencerNode', sequencerNode)
      this.set('startTimeOfPlayingNodeInContext', sequencerNode? startTimeInContext: null);
    }
  },

  stopPlayingRenderedBuffer: function (bufferSourceFinishPlaying) {
    if (this._audioContextScheduler) {
      this._audioContextScheduler.stop()
      for (let i = 0; i < this._arrayOfAudioNodesUsed.length; i++) {
        const audioNodeUsed = this._arrayOfAudioNodesUsed[i]
        try {
          audioNodeUsed.disconnect()
          audioNodeUsed.isConnected = false
        } catch (error) {
          console.error(error)
        }
      }

      for (let i = 0; i < this._arrayOfVideoNodesUsed.length; i++) {
        const videoNodeUsed = this._arrayOfVideoNodesUsed[i]
        try {
          videoNodeUsed.disconnect()
          videoNodeUsed.destroy()
          videoNodeUsed.isConnected = false
        } catch (error) {
          console.error(error)
        }
      }

      this._audioContextScheduler = null
      this._clockContextStartTimeOfFirstNodeScheduled = null
      this._clockVideoContextStartTimeOfFirstNodeScheduled = null
      this._finishPlayingClockEvent = null
      this._arrayOfAudioNodesUsed = []
      this._arrayOfVideoNodesUsed = []
    }
    if (this._nextBatchOfMergedNodePlaybackRenderingTimeoutId) {
      clearTimeout(this._nextBatchOfMergedNodePlaybackRenderingTimeoutId)
    }
    this._nextBatchOfMergedNodePlaybackRenderingTimeoutId = null
    this._nextMergedSchedulingNodeIndexToRender = null
    this._numberOfMergedVideoNodes = null;
    this.set('playbackStopped', true)
    this._updateCurrentlyPlayingSequencerNode(null)
    this.set('renderedBufferIsPlaying', false)
    this.getSequencer().setRenderedBufferPlaying(false)
    if (bufferSourceFinishPlaying) {
      this.getSequencer().resetPlaybackPositionOnAudioPlaybackCompleted()
    }
    return RSVP.Promise.resolve(true)
  },

  _disconnectArrayOfMergeNodes: function (mergedNodeSchedulingInfoArray) {
    if (mergedNodeSchedulingInfoArray) {
      mergedNodeSchedulingInfoArray.map(function (mergeNode) {
        try {
          mergeNode.getAudioNode().disconnect()
          mergeNode.getAudioNode().isConnected = false
          mergeNode.getAudioNode().buffer = null
        } catch (error) {
          console.error(error)
        }
      })
    }
  },

  getSequencerNodeAtPositionInMilliseconds: function (
    positionInMilliseconds
  ) {
    const sequencerNodeSchedulingInfosAtPosition = this.getSequencerNodeSchedulingInfosAtPositionInMilliseconds(
      positionInMilliseconds
    )
    return sequencerNodeSchedulingInfosAtPosition &&
      sequencerNodeSchedulingInfosAtPosition.length > 0
      ? sequencerNodeSchedulingInfosAtPosition[0].getSequencerNodeReference()
      : null
  },

  getSequencerNodeSchedulingInfosAtPositionInMilliseconds: function (
    positionInMilliseconds
  ) {
    let previousStartSegmentInMilliseconds = 0
    if (positionInMilliseconds > 0) {
      for (const startTimeInMilliseconds in this
        ._startTimeInMillisecondsToNodeSchedulingInfos) {
        if (startTimeInMilliseconds > previousStartSegmentInMilliseconds) {
          if (
            positionInMilliseconds >= previousStartSegmentInMilliseconds &&
            positionInMilliseconds <= startTimeInMilliseconds
          ) {
            return this._startTimeInMillisecondsToNodeSchedulingInfos[
              previousStartSegmentInMilliseconds
            ]
          }
        }
        previousStartSegmentInMilliseconds = startTimeInMilliseconds
      }
    }
    return this._startTimeInMillisecondsToNodeSchedulingInfos[
      previousStartSegmentInMilliseconds
    ]
  },

  isPlaying: function () {
    return this.get('renderedBufferIsPlaying')
  },

  isLatestRenderedBufferCreated: function () {
    return false
  },

  _isRenderingForPlayback: function () {
    return true
  },

  _applyAllFiltersUsingNodeSchedulingInfo: function (
    nodeSchedulingInfo,
    audioContext,
    startTimeForNodeInSeconds,
    endTimeForNodeInSeconds,
    arrayOfAudioNodesUsed,
    sequencerRendererTracksInfo
  ) {
    if (nodeSchedulingInfo && nodeSchedulingInfo.getAudioNode()) {
      const targetNode = nodeSchedulingInfo.getSequencerNodeReference()
      const audioNode = nodeSchedulingInfo.getAudioNode()
      const sequencerNode = nodeSchedulingInfo.getSequencerNodeReference()
      const allAudioFilters = nodeSchedulingInfo.getAllAudioFiltersForRendering(
        audioContext,
        startTimeForNodeInSeconds,
        endTimeForNodeInSeconds,
        arrayOfAudioNodesUsed,
        this._isRenderingForPlayback()
      )
      const audioNodeReturned = audioNode
      arrayOfAudioNodesUsed.push(audioNode)
      /*if(allAudioFilters){
          for(let i = 0; i < allAudioFilters.length; i++){
            const nodeFilter = allAudioFilters[i];
            audioNodeReturned = nodeFilter.applyFilterToNode(audioNodeReturned, audioContext, arrayOfAudioNodesUsed, startTimeForNodeInSeconds, endTimeForNodeInSeconds );
          }
        }
        return RSVP.Promise.resolve(audioNodeReturned);*/
      return this._applyAllFiltersUsingNodeSchedulingInfoAsync(
        audioNodeReturned,
        allAudioFilters,
        0,
        audioContext,
        startTimeForNodeInSeconds,
        endTimeForNodeInSeconds,
        arrayOfAudioNodesUsed,
        sequencerRendererTracksInfo
      )
    }
    return RSVP.Promise.resolve()
  },

  _applyAllFiltersUsingNodeSchedulingInfoAsync: function (
    audioNodeReturned,
    allAudioFilters,
    audioFilterIndexToStartProcessingFrom,
    audioContext,
    startTimeForNodeInSeconds,
    endTimeForNodeInSeconds,
    arrayOfAudioNodesUsed,
    sequencerRendererTracksInfo
  ) {
    if (audioFilterIndexToStartProcessingFrom < allAudioFilters.length) {
      const nodeFilter = allAudioFilters[audioFilterIndexToStartProcessingFrom]
      return nodeFilter
        .applyFilter(
          audioNodeReturned,
          audioContext,
          arrayOfAudioNodesUsed,
          startTimeForNodeInSeconds,
          endTimeForNodeInSeconds,
          sequencerRendererTracksInfo
        )
        .then(
          function (newAudioNodeReturned) {
            return this._applyAllFiltersUsingNodeSchedulingInfoAsync(
              newAudioNodeReturned,
              allAudioFilters,
              audioFilterIndexToStartProcessingFrom + 1,
              audioContext,
              startTimeForNodeInSeconds,
              endTimeForNodeInSeconds,
              arrayOfAudioNodesUsed,
              sequencerRendererTracksInfo
            )
          }.bind(this)
        )
    } else {
      return RSVP.Promise.resolve(audioNodeReturned)
    }
  },

  _onAudioBufferPlaybackLoading: function () {
    this.set('renderedBufferPlaybackLoading', true)
    this.set('playbackStopped', false)
    this.getSequencer().setRenderedBufferPlaybackLoading(true)
  },

  /*
    _scheduleAudioNodeToContext : function(sequencerNodeToStartFrom, stopAfterMilliseconds, sequencerNodeToStopPlayAt, onAudioErrorPlayingHandler){

      const onPlaybackError = (function(error){
        console.error(error);
        if(!TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK || !window.chrome || (error && error.code !== 3) ){
          window.alertErrorMessage(error);
          this.stopPlayingRenderedBuffer(true);
        }
        if(onAudioErrorPlayingHandler){
          onAudioErrorPlayingHandler(error);
        }
      }).bind(this)

      try{
        this._onAudioBufferPlaybackLoading();

        this._clock = new WAAClock(this.getAudioContext());
        this._clock.start();
        this._clockContextStartTimeOfFirstNodeScheduled = this.getAudioContext().currentTime + SCHEDULING_DELAY_IN_SECONDS;
        this._firstNodeSchedulingInfo = this._getSchedulingNodeInfoForSequencerNode(sequencerNodeToStartFrom ? sequencerNodeToStartFrom: this.getSequencer().getFirstNode());
        this._arrayOfAudioNodesUsed =  this._getAllTrackNodes();

        this._nextNodeToSchedule = this._firstNodeSchedulingInfo.getSequencerNodeReference();
        while(this._nextNodeToSchedule && (!sequencerNodeToStopPlayAt || (this._nextNodeToSchedule != sequencerNodeToStopPlayAt)) && (!stopAfterMilliseconds ||   endTimeOfLastNodeScheduled < stopAfterMilliseconds)) {
          const nodeSchedulingInfo = this._getSchedulingNodeInfoForSequencerNode(this._nextNodeToSchedule);
          if(nodeSchedulingInfo){
            const audioNode = nodeSchedulingInfo.getAudioNode();
            const startTimeForNode = this._clockContextStartTimeOfFirstNodeScheduled + nodeSchedulingInfo.getStartTimeOffset()/1000 - this._firstNodeSchedulingInfo.getStartTimeOffset()/1000;
            const endTimeForNode = startTimeForNode + (nodeSchedulingInfo.getDuration()/1000)
            const lastNodeInChain = this._applyAllFiltersUsingNodeSchedulingInfo(nodeSchedulingInfo, this.getAudioContext(), startTimeForNode, endTimeForNode, this._arrayOfAudioNodesUsed);
            const audioContext = this.getAudioContext();
            const startEvent = this._clock.callbackAtTime((function() { 
              this.lastNodeInChain.connect(this.nodeSchedulingInfo.getTrackAudioNode()); 
              this.audioNode.start(this.startTimeForNode, this.nodeSchedulingInfo.getStartTimeOffsetForSourceBuffer()/1000, this.nodeSchedulingInfo.getDuration()/1000) ;
              this.self._updateCurrentlyPlayingSequencerNode(this.nodeSchedulingInfo.getSequencerNodeReference());
            }).bind({"self": this,"audioNode":audioNode,"audioContext":audioContext,"startTimeForNode":startTimeForNode,"nodeSchedulingInfo": nodeSchedulingInfo,"lastNodeInChain": lastNodeInChain}), startTimeForNode);
            startEvent.nodeSchedulingInfo = nodeSchedulingInfo;
          }
          this._nextNodeToSchedule = this._nextNodeToSchedule.getNext();
        }
        this._finishPlayingClockEvent = this._clock.callbackAtTime((function() { 
          this._onAudioBufferFinishPlaying();
        }).bind(this), this._clockContextStartTimeOfFirstNodeScheduled + this.getFinalRenderDurationInMilliseconds()/1000 - this._firstNodeSchedulingInfo.getStartTimeOffset()/1000);

        this._masterTrackNode.connect(this.getAudioContext().destination);
        this._onAudioBufferStartingPlaying();


      }catch(error){
        onPlaybackError(error)
      }


    },*/

  _getLowestRelativeStartTimeFromStart: function (
    nodeSchedulingInfoArray,
    playbackStartTimeOffset
  ) {
    if (!playbackStartTimeOffset) {
      playbackStartTimeOffset = 0
    }
    return nodeSchedulingInfoArray.reduce(function (
      lowestRelativeStartTime,
      nodeSchedulingInfo
    ) {
      return nodeSchedulingInfo.getStartTimeOffset() -
        playbackStartTimeOffset <
        lowestRelativeStartTime
        ? nodeSchedulingInfo.getStartTimeOffset() - playbackStartTimeOffset
        : lowestRelativeStartTime
    },
    0)
  },

  _downloadAndCacheAllVideoMediaFilesAsBlobForMergedSchedulingNode: function (
    mergedNodeSchedulingInfoArray,
    videoContext
  ) {
    if (mergedNodeSchedulingInfoArray && videoContext) {
      const downloadAndCacheUrlAsBlobPromiseArray = mergedNodeSchedulingInfoArray.map(
        function (mergedNode) {
          if (mergedNode.isSequencerNodeReferenceHasVideo()) {
            //Only cache media for video nodes
            return mergedNode.downloadAndCacheUrlAsBlob()
          } else {
            return null
          }
        }.bind(this)
      )
      return RSVP.Promise.all(downloadAndCacheUrlAsBlobPromiseArray)
    } else {
      return RSVP.Promise.resolve()
    }
  },

  _calculateCanvasSizeFromVideoUrls: function (videoUrls) {
    if (videoUrls) {
      const promiseArray = _.uniq(videoUrls).map((function (videoUrl) {
        if (videoUrl) {
          return this.getSequencer().getAudioBufferCache().loadUrlInVideoSizeCache(
            videoUrl
          )
        } else {
          return null
        }
      }).bind(this))
      return RSVP.Promise.all(promiseArray).then(
        function (videoSizeArray) {
          return videoSizeArray.reduce(
            function (currentSize, videoSize) {
              if (videoSize) {
                if (videoSize.videoHeight > currentSize.height) {
                  currentSize.height = videoSize.videoHeight
                }
                if (videoSize.videoWidth > currentSize.width) {
                  currentSize.width = videoSize.videoWidth
                }
              }
              return currentSize
            },
            { height: 0, width: 0 }
          )
        }.bind(this)
      )
    }
  },

  _scheduleAudioNodeToContext: function (
    sequencerNodeToStartFrom,
    stopAfterMilliseconds,
    sequencerNodeToStopPlayAt,
    onAudioErrorPlayingHandler,
    audioContextUsed,
    applyGainBeforeDestination,
    gainLevelToApply,
    videoContext,
    offscreenVideoRendering,
    shouldSequencerNodeBePlayedValidatorFunction,
    includeDeletedSegmentsWhenPossible
  ) {
    this._arrayOfAudioNodesUsed = []

    const mergedNodeSchedulingInfoArray = this._createMergeNodesForPlayback(
      sequencerNodeToStartFrom,
      stopAfterMilliseconds,
      sequencerNodeToStopPlayAt,
      audioContextUsed,
      shouldSequencerNodeBePlayedValidatorFunction,
      includeDeletedSegmentsWhenPossible
    )

    const downloadAndCacheUrlAsBlobPromiseArray =
      mergedNodeSchedulingInfoArray && videoContext
        ? this._downloadAndCacheAllVideoMediaFilesAsBlobForMergedSchedulingNode(
            mergedNodeSchedulingInfoArray,
            videoContext
          )
        : RSVP.Promise.resolve()
    return downloadAndCacheUrlAsBlobPromiseArray
      .then(
        function (videoUrls) {
          if (
            offscreenVideoRendering &&
            videoContext &&
            videoUrls &&
            videoUrls.length > 0
          ) {
            return this._calculateCanvasSizeFromVideoUrls(videoUrls).then(
              function (canvasSizeBasedOnMediaElements) {
                if(this.getSequencer()
                .getSequencerSettings().hasVideoResolution()){
                  const videoSize = this.getSequencer().getSequencerSettings().getVideoResolution();
                  videoContext.element.width = videoSize.width;
                  videoContext.element.height = videoSize.height;
                }else{
                  videoContext.element.width = canvasSizeBasedOnMediaElements.width;
                  videoContext.element.height = canvasSizeBasedOnMediaElements.height;
                }
                return this._createSequencerRendererTracksInfo(
                  audioContextUsed,
                  this._arrayOfAudioNodesUsed,
                  videoContext,
                  this._arrayOfVideoNodesUsed
                )
              }.bind(this)
            )
          }
          return this._createSequencerRendererTracksInfo(
            audioContextUsed,
            this._arrayOfAudioNodesUsed,
            videoContext,
            this._arrayOfVideoNodesUsed
          )
        }.bind(this)
      )
      .then(
        function (sequencerRendererTracksInfo) {
          try {
            if (!this.get('playbackStopped')) {
              const isSchedulingForPlayback = !(
                audioContextUsed instanceof OfflineAudioContext
              )
              const totalPlaybackDurationInMilliseconds =
                mergedNodeSchedulingInfoArray.totalPlaybackDurationInMilliseconds
              if (
                mergedNodeSchedulingInfoArray &&
                mergedNodeSchedulingInfoArray.length > 0
              ) {
                this._audioContextScheduler = new AudioContextScheduler({
                  audioContext: audioContextUsed
                })
                this._audioContextScheduler.start()
                this._clockContextStartTimeOfFirstNodeScheduled =
                  audioContextUsed.currentTime +
                  (isSchedulingForPlayback
                    ? SCHEDULING_DELAY_IN_SECONDS_FOR_PLAYBACK
                    : 0)
                this._clockVideoContextStartTimeOfFirstNodeScheduled = videoContext
                  ? videoContext.currentTime +
                    (isSchedulingForPlayback
                      ? SCHEDULING_DELAY_IN_SECONDS_FOR_PLAYBACK
                      : 0)
                  : null
                this._firstNodeSchedulingInfo =
                  mergedNodeSchedulingInfoArray[0]
                const lowestRelativeStartTimeFromStart = this._getLowestRelativeStartTimeFromStart(
                  mergedNodeSchedulingInfoArray,
                  this._firstNodeSchedulingInfo.getStartTimeOffset()
                )
                if (lowestRelativeStartTimeFromStart < 0) {
                  //adding the opposite to compensate for the relative startime and make sure that playback starts on time
                  this._clockContextStartTimeOfFirstNodeScheduled =
                    this._clockContextStartTimeOfFirstNodeScheduled +
                    (lowestRelativeStartTimeFromStart / 1000) * -1
                  this._clockVideoContextStartTimeOfFirstNodeScheduled =
                    this._clockVideoContextStartTimeOfFirstNodeScheduled +
                    (lowestRelativeStartTimeFromStart / 1000) * -1
                }

                /*for(let audioType in audioTypeToTrackNode){
              this._arrayOfAudioNodesUsed.push(audioTypeToTrackNode[audioType]);
            }*/
                if (applyGainBeforeDestination) {
                  const gainNodeToApplyBeforeDestination = audioContextUsed.createGain()
                  gainNodeToApplyBeforeDestination.gain.value = gainLevelToApply
                  this._arrayOfAudioNodesUsed.push(
                    gainNodeToApplyBeforeDestination
                  )
                  sequencerRendererTracksInfo
                    .getEndNodeOnMasterTrack()
                    .connect(gainNodeToApplyBeforeDestination)
                  gainNodeToApplyBeforeDestination.connect(
                    audioContextUsed.destination
                  )
                } else {
                  sequencerRendererTracksInfo
                    .getEndNodeOnMasterTrack()
                    .connect(audioContextUsed.destination)
                }
                if (videoContext) {
                  sequencerRendererTracksInfo
                    .getEndNodeOnVideoMasterTrack()
                    .connect(
                      sequencerRendererTracksInfo.getVideoContext()
                        .destination
                    )
                }

                this._nextMergedSchedulingNodeIndexToRender = 0
                this._numberOfMergedVideoNodes = mergedNodeSchedulingInfoArray.reduce(
                  function (currentResult, mergeNode) {
                    if (mergeNode.isSequencerNodeReferenceHasVideo()) {
                      return (currentResult = currentResult + 1)
                    }
                    return currentResult
                  },
                  0
                )
                this._renderMergedNodeSchedulingInfoArrayInBatch(
                  mergedNodeSchedulingInfoArray,
                  null,
                  videoContext,
                  offscreenVideoRendering,
                  sequencerRendererTracksInfo,
                  isSchedulingForPlayback,
                  RENDER_MERGED_NODE_IN_BACTCH_DURING_LIVE_PLAYBACK
                )
                return mergedNodeSchedulingInfoArray
              } else {
                if (isSchedulingForPlayback) {
                  this.stopPlayingRenderedBuffer(true)
                }
              }
            }
          } catch (error) {
            if (onAudioErrorPlayingHandler) {
              onAudioErrorPlayingHandler(error)
            } else {
              throw error
            }
          }
        }.bind(this)
      )
  },

  _shouldMergedNodeWithIndexBeScheduled: function (
    nodeIndex,
    renderInBatch,
    mergedNodeSchedulingInfoArray,
    startingIndex,
    isSchedulingForPlayback
  ) {
    if (nodeIndex < mergedNodeSchedulingInfoArray.length) {
      if (!renderInBatch || !isSchedulingForPlayback) {
        //Scheduling all the merged nodes all at once
        return true
      } else {
        if (
          mergedNodeSchedulingInfoArray[nodeIndex] &&
          mergedNodeSchedulingInfoArray[nodeIndex].getStartTimeOffset &&
          mergedNodeSchedulingInfoArray[startingIndex] &&
          mergedNodeSchedulingInfoArray[startingIndex].getStartTimeOffset &&
          mergedNodeSchedulingInfoArray[nodeIndex].getStartTimeOffset() -
            mergedNodeSchedulingInfoArray[
              startingIndex
            ].getStartTimeOffset() <
            MAX_TIME_TO_SCHEDULE_MERGED_NODES_AHEAD
        ) {
          //we have not reached the quota of merge node that can be scheduled per batch
          return true
        } else {
          if (nodeIndex === startingIndex) {
            //No node have been scheduled yet in this batch - at least one node need to be schedule per batch
            return true
          }
        }
      }
    }
    return false
  },

  _renderMergedNodeSchedulingInfoArrayInBatch: function (
    mergedNodeSchedulingInfoArray,
    preloadedHTMLMediaElementsForMergeNodeArray,
    videoContext,
    offscreenVideoRendering,
    sequencerRendererTracksInfo,
    isSchedulingForPlayback,
    renderInBatch
  ) {
    if (!this.get('playbackStopped')) {
      const totalPlaybackDurationInMilliseconds =
        mergedNodeSchedulingInfoArray.totalPlaybackDurationInMilliseconds
      const startingIndex = this._nextMergedSchedulingNodeIndexToRender

      for (
        let i = this._nextMergedSchedulingNodeIndexToRender;
        this._shouldMergedNodeWithIndexBeScheduled(
          i,
          renderInBatch,
          mergedNodeSchedulingInfoArray,
          startingIndex,
          isSchedulingForPlayback
        );
        i++
      ) {
        const nodeSchedulingInfo = mergedNodeSchedulingInfoArray[i]
        const preloadedHTMLMediaElement =
          preloadedHTMLMediaElementsForMergeNodeArray &&
          preloadedHTMLMediaElementsForMergeNodeArray.length > i
            ? preloadedHTMLMediaElementsForMergeNodeArray[i]
            : null
        this._renderNodeSchedulingInfo(
          this._audioContextScheduler,
          sequencerRendererTracksInfo,
          nodeSchedulingInfo,
          this._clockContextStartTimeOfFirstNodeScheduled,
          this._firstNodeSchedulingInfo,
          this._arrayOfAudioNodesUsed,
          videoContext,
          this._clockVideoContextStartTimeOfFirstNodeScheduled,
          preloadedHTMLMediaElement,
          offscreenVideoRendering
        )
        this._nextMergedSchedulingNodeIndexToRender = i + 1
      }
      if (
        this._nextMergedSchedulingNodeIndexToRender <
        mergedNodeSchedulingInfoArray.length
      ) {
        //There are still some merge node left to render
        // so schedule the next batch rendering TIME_BEFORE_NEXT_SCHEDULE_PLAYBACK_BATCH before the currently render nodes completes playback
        this._nextBatchOfMergedNodePlaybackRenderingTimeoutId = setTimeout(
          function () {
            this._renderMergedNodeSchedulingInfoArrayInBatch(
              mergedNodeSchedulingInfoArray,
              preloadedHTMLMediaElementsForMergeNodeArray,
              videoContext,
              offscreenVideoRendering,
              sequencerRendererTracksInfo,
              isSchedulingForPlayback,
              renderInBatch
            )
          }.bind(this),
          MAX_TIME_TO_SCHEDULE_MERGED_NODES_AHEAD -
            TIME_BEFORE_NEXT_SCHEDULE_PLAYBACK_BATCH
        )
      } else {
        //All the merge nodes have been scheduled
        if (isSchedulingForPlayback) {
          //If this is scheduling is meant for playback, make sure that when the audio finished playing, the playback is stopped. This should alwyas be executed
          this._finishPlayingClockEvent = this._audioContextScheduler.callbackAtTime(
            function () {
              this._onAudioBufferFinishPlaying()
            }.bind(this),
            this._clockContextStartTimeOfFirstNodeScheduled +
              totalPlaybackDurationInMilliseconds / 1000,
            HIGH_TOLERANCE_VALUES_FOR_PLAYBACK_FUNCTION
          )
        }
      }
    }
  },

  _renderNodeSchedulingInfo: function (
    audioContextScheduler,
    sequencerRendererTracksInfo,
    nodeSchedulingInfo,
    clockContextStartTimeOfFirstNodeScheduled,
    firstNodeSchedulingInfo,
    arrayOfAudioNodesUsed,
    videoContext,
    clockVideoContextStartTimeOfFirstNodeScheduled,
    preloadedHTMLMediaElement,
    offscreenVideoRendering
  ) {
    if (nodeSchedulingInfo) {
      const audioNode = nodeSchedulingInfo.getAudioNode()
      const startTimeForNode =
        clockContextStartTimeOfFirstNodeScheduled +
        nodeSchedulingInfo.getStartTimeOffset() / 1000 -
        firstNodeSchedulingInfo.getStartTimeOffset() / 1000
      const startTimeForVideoNode = videoContext
        ? (nodeSchedulingInfo.getStartTimeOffset() -
            nodeSchedulingInfo.getRelativeStartTime()) /
            1000 -
          (firstNodeSchedulingInfo.getStartTimeOffset() -
            firstNodeSchedulingInfo.getRelativeStartTime()) /
            1000
        : null //Remove crossfade time if applicable since it is used for only audio at the moment
      const endTimeForNode =
        startTimeForNode + nodeSchedulingInfo.getDuration() / 1000
      const endTimeForAudioBuffer =
        startTimeForNode +
        nodeSchedulingInfo.getAudioBufferPlaybackDuration() / 1000
      const finalEndTimeForNodePlayback =
        endTimeForNode < endTimeForAudioBuffer
          ? endTimeForNode
          : endTimeForAudioBuffer
          let lastNodeInChainPromise = null
      if (
        nodeSchedulingInfo instanceof
        MergedIncompleteWrapSequencerNodeSchedulingInfo
      ) {
        const startTimeForNodeInPast =
          clockContextStartTimeOfFirstNodeScheduled +
          nodeSchedulingInfo.getInitialStartTimeOffset() / 1000 -
          firstNodeSchedulingInfo.getStartTimeOffset() / 1000
        lastNodeInChainPromise = this._applyAllFiltersUsingNodeSchedulingInfo(
          nodeSchedulingInfo,
          audioContextScheduler.getAudioContext(),
          startTimeForNodeInPast,
          finalEndTimeForNodePlayback,
          arrayOfAudioNodesUsed,
          sequencerRendererTracksInfo
        )
      } else {
        lastNodeInChainPromise = this._applyAllFiltersUsingNodeSchedulingInfo(
          nodeSchedulingInfo,
          audioContextScheduler.getAudioContext(),
          startTimeForNode,
          finalEndTimeForNodePlayback,
          arrayOfAudioNodesUsed,
          sequencerRendererTracksInfo
        )
      }

      const audioType = this._getAudioType(
        nodeSchedulingInfo.getSequencerNodeReference()
      )
      const offsetBufferNode =
        nodeSchedulingInfo.getStartTimeOffsetForSourceBuffer() / 1000 +
        nodeSchedulingInfo.getStartTimeOffsetLoopForSourceBuffer() / 1000
      const offsetBufferNodeForVideo =
        offsetBufferNode - nodeSchedulingInfo.getRelativeStartTime() / 1000 //Remove crossfade time at the beginning at the end since it is only used for audio
      const bufferNodeDuration =
        nodeSchedulingInfo.getDuration() <
        nodeSchedulingInfo.getAudioBufferPlaybackDuration()
          ? nodeSchedulingInfo.getDuration() / 1000
          : nodeSchedulingInfo.getAudioBufferPlaybackDuration() / 1000
      const bufferNodeDurationForVideo = bufferNodeDuration //Remove crossfade time at the beginning at the end since it is only used for audio
      if (offscreenVideoRendering) {
        this._renderVideoNodeFromNodeSchedulingInfoIfApplicable(
          videoContext,
          sequencerRendererTracksInfo,
          nodeSchedulingInfo,
          this._arrayOfVideoNodesUsed,
          preloadedHTMLMediaElement,
          offsetBufferNode,
          bufferNodeDuration,
          firstNodeSchedulingInfo
        )
      }
      lastNodeInChainPromise.then(
        function (lastNodeInChain) {
          audioContextScheduler.callbackAtTime(
            function () {
              this.lastNodeInChain.connect(
                this.sequencerRendererTracksInfo.getStartAudioTrackNodeByAudioType(
                  this.audioType
                )
              )
              if (this.audioNode.buffer._durationCalculatedByMediaElement && this.audioNode.buffer._durationCalculatedByMediaElement > this.audioNode.buffer.duration) {
                //According to my observation the only time this is a problem and time get shrinked in the buffer is when the buffer length is smaller, if the buffer length is bigger thant the buffer
                //length from the HTML audio element than for now we will act like there is no problem and use the from the transcription file without streching
                this.audioNode.start(
                  this.startTimeForNode < 0 ? 0 : this.startTimeForNode,
                  (this.offsetBufferNode * this.audioNode.buffer.duration) /
                    this.audioNode.buffer._durationCalculatedByMediaElement,
                  (this.bufferNodeDuration * this.audioNode.buffer.duration) /
                    this.audioNode.buffer._durationCalculatedByMediaElement
                )
              } else {
                this.audioNode.start(
                  this.startTimeForNode < 0 ? 0 : this.startTimeForNode,
                  this.offsetBufferNode,
                  this.bufferNodeDuration
                )
              }
              if (
                !this.audioContextScheduler.isOfflineContext() &&
                !(
                  this.nodeSchedulingInfo instanceof
                  MergedSequencerNodeSchedulingInfo
                )
              ) {
                this.self._updateCurrentlyPlayingSequencerNode(
                  this.nodeSchedulingInfo.getSequencerNodeReference(), startTimeForNode
                )
              }
              if (!offscreenVideoRendering) {
                this.self._renderVideoNodeFromNodeSchedulingInfoIfApplicable(
                  videoContext,
                  this.sequencerRendererTracksInfo,
                  this.nodeSchedulingInfo,
                  this.self._arrayOfVideoNodesUsed,
                  preloadedHTMLMediaElement,
                  this.offsetBufferNodeForVideo,
                  this.bufferNodeDuration,
                  this.startTimeForVideoNode,
                  true
                )
              }
            }.bind({
              self: this,
              audioNode: audioNode,
              audioType: audioType,
              offsetBufferNode: offsetBufferNode,
              offsetBufferNodeForVideo: offsetBufferNodeForVideo,
              bufferNodeDuration: bufferNodeDuration,
              sequencerRendererTracksInfo: sequencerRendererTracksInfo,
              audioContextScheduler: audioContextScheduler,
              startTimeForNode: startTimeForNode,
              nodeSchedulingInfo: nodeSchedulingInfo,
              lastNodeInChain: lastNodeInChain,
              startTimeForVideoNode: startTimeForVideoNode,
              firstNodeSchedulingInfo: firstNodeSchedulingInfo
            }),
            startTimeForNode,
            TOLERANCE_VALUES_FOR_PLAYBACK_FUNCTION
          )
          this._scheduleDisplayingOfEndWrapNodePlayingIfApplicable(
            nodeSchedulingInfo,
            audioContextScheduler,
            endTimeForNode
          )
          this._scheduleDisplayingOfPlayingNodesForMergeNodeScheduledInfoIfApplicable(
            nodeSchedulingInfo,
            audioContextScheduler,
            clockContextStartTimeOfFirstNodeScheduled,
            firstNodeSchedulingInfo
          )
        }.bind(this)
      )
    } else {
      console.warn('Not expecting the nodeSchedulingInfo to be null')
    }
  },

  _renderVideoNodeFromNodeSchedulingInfoIfApplicable: function (
    videoContext,
    sequencerRendererTracksInfo,
    nodeSchedulingInfo,
    arrayOfVideoNodesUsed,
    preloadedHTMLMediaElement,
    offsetBufferNode,
    nodeDuration,
    firstNodeSchedulingInfo,
    scheduleNow
  ) {
    if (
      videoContext &&
      nodeSchedulingInfo.getSequencerNodeReference() &&
      nodeSchedulingInfo.getSequencerNodeReference().getAudioSegment() &&
      nodeSchedulingInfo
        .getSequencerNodeReference()
        .getAudioSegment()
        .getHasVideo()
    ) {
      //render video node
      const audioType = this._getAudioType(
        nodeSchedulingInfo.getSequencerNodeReference()
      )
      const startTimeForVideoNode = scheduleNow
        ? 0
        : nodeSchedulingInfo.getStartTimeOffset() / 1000 -
          firstNodeSchedulingInfo.getStartTimeOffset() / 1000
          let htmlMediaElementOrMediaUrl = nodeSchedulingInfo.getAudioSegmentMediaUrl()
      if (nodeSchedulingInfo.getCachedBlobUrl()) {
        htmlMediaElementOrMediaUrl = nodeSchedulingInfo.getCachedBlobUrl()
      } else {
        if (preloadedHTMLMediaElement) {
          htmlMediaElementOrMediaUrl = preloadedHTMLMediaElement
        }
      }
      const videoNode = videoContext.video(
        htmlMediaElementOrMediaUrl,
        offsetBufferNode
      )
      //Scaling the video to make sure that it keeps the same proportion independly of the size of canvas
      const scaleEffect = videoContext.effect(
        VideoContext.DEFINITIONS.AAF_VIDEO_SCALE
      )
      scaleEffect.scaleX = nodeSchedulingInfo.getCachedVideoWidth()
        ? (nodeSchedulingInfo.getCachedVideoWidth() *
            videoContext.element.height) /
          (videoContext.element.width *
            nodeSchedulingInfo.getCachedVideoHeight())
        : 1
      scaleEffect.scaleY = 1
      videoNode.connect(scaleEffect)

      videoNode.volume = 0

      videoNode.start(startTimeForVideoNode)
      videoNode.stop(startTimeForVideoNode + nodeDuration)
      scaleEffect.connect(
        sequencerRendererTracksInfo.getStartVideoTrackNodeByAudioType(
          audioType
        )
      )
      videoNode.isConnected = true
      videoNode.registerCallback('ended', function () {
        videoNode.destroy()
      })
      arrayOfVideoNodesUsed.push(videoNode)
    }
  },

  _scheduleDisplayingOfEndWrapNodePlayingIfApplicable: function (
    nodeSchedulingInfo,
    audioContextScheduler,
    endTimeForNodeInContext
  ) {
    if (
      !audioContextScheduler.isOfflineContext() &&
      Utils.getInstance().isStartMusicWrapSequencerNodeInstance(
        nodeSchedulingInfo.getSequencerNodeReference()
      )
    ) {
      const endWrapSequencerNode = nodeSchedulingInfo
        .getSequencerNodeReference()
        .getEndWrapSequencerNode()
      if (endWrapSequencerNode.getCurrentNodeMargin() > 0) {
        const endNodeStartTimeInContext =
          endTimeForNodeInContext -
          endWrapSequencerNode.getCurrentNodeMargin() / 1000
        audioContextScheduler.callbackAtTime(
          function () {
            this.self._updateCurrentlyPlayingSequencerNode(
              this.endWrapSequencerNode,
              endNodeStartTimeInContext
            )
          }.bind({
            self: this,
            endWrapSequencerNode: endWrapSequencerNode
          }),
          endNodeStartTimeInContext
        )
      }
    }
  },

  _scheduleDisplayingOfPlayingNodesForMergeNodeScheduledInfoIfApplicable: function (
    nodeSchedulingInfo,
    audioContextScheduler,
    clockContextStartTimeOfFirstNodeScheduled,
    firstNodeSchedulingInfo
  ) {
    if (
      !audioContextScheduler.isOfflineContext() &&
      nodeSchedulingInfo instanceof MergedSequencerNodeSchedulingInfo
    ) {
      nodeSchedulingInfo.getSchedulingNodeToBePlayed().map(
        function (childNodeSchedulingInfo) {
          const startTimeInContext = clockContextStartTimeOfFirstNodeScheduled +
          childNodeSchedulingInfo.getStartTimeOffset() / 1000 -
          firstNodeSchedulingInfo.getStartTimeOffset() / 1000;
          audioContextScheduler.callbackAtTime(
            function () {
              this.self._updateCurrentlyPlayingSequencerNode(
                this.childNodeSchedulingInfo.getSequencerNodeReference(),startTimeInContext
              )
            }.bind({
              self: this,
              childNodeSchedulingInfo: childNodeSchedulingInfo
            }),
            startTimeInContext
          )
        }.bind(this)
      )
    }
  },

  playRenderedBuffer: function (
    sequencerNodeToStartFrom,
    stopAfterMilliseconds,
    sequencerNodeToStopPlayAt,
    onAudioErrorPlayingHandler,
    videoContext,
    shouldSequencerNodeBePlayedValidatorFunction,
    includeDeletedSegmentsWhenPossible
  ) {
    if (sequencerNodeToStartFrom) {
      this.stopPlayingRenderedBuffer()
      this._onAudioBufferPlaybackLoading()
      /*if(videoContext){
          videoContext.reset();
        }*/
      this._scheduleAudioNodeToContext(
        sequencerNodeToStartFrom,
        stopAfterMilliseconds,
        sequencerNodeToStopPlayAt,
        onAudioErrorPlayingHandler,
        this.getAudioContext(),
        true,
        0.75,
        videoContext, null, shouldSequencerNodeBePlayedValidatorFunction,
        includeDeletedSegmentsWhenPossible
      ).then(
        function () {
          if (videoContext && !this.get('playbackStopped')) {
            videoContext.play()
          }
          return this._onAudioBufferStartingPlaying()
        }.bind(this)
      )
    } else {
      return RSVP.Promise.resolve()
    }
  },

  _onAudioBufferFinishPlaying: function () {
    this.stopPlayingRenderedBuffer(true)
  },

  _onAudioBufferStartingPlaying: function () {
    this._onAudioBufferPlaybackLoaded()
    this.set('renderedBufferIsPlaying', true)
    this.getSequencer().setRenderedBufferPlaying(true)
    //this._scheduleStopPlayerOnAudioStartedPlaying()
  },

  _onAudioBufferPlaybackLoaded: function () {
    this.set('renderedBufferPlaybackLoading', false)
    this.getSequencer().setRenderedBufferPlaybackLoading(false)
  },

  getFinalRenderDurationInMilliseconds: function () {
    return this._totalBufferDurationInMilliseconds
  },

  getPlaybackProgressInMilliseconds: function () {
    return this._audioContextScheduler
      ? (this.getAudioContext().currentTime -
          this._clockContextStartTimeOfFirstNodeScheduled) *
          1000 +
          this._firstNodeSchedulingInfo.getStartTimeOffset()
      : 0
  },

  getPlaybackAudioContextCurrentTime: function () {
    return this.getAudioContext().currentTime
  },

  canPlayThrough: function () {
    return this._audioContextScheduler ? true : false
  },

  createFFMPEGParamsToRenderVideoByContatenation: function (
    sequencerNodeToStartFrom,
    sequencerNodeToEndOn,
    audioUrl,
    srtFileUrl,
    shouldSequencerNodeBePlayedValidatorFunction,
    includeDeletedSegmentsWhenPossible
  ) {
    const canvasForRenderingVideo = document.createElement('canvas')
    let finalBufferDurationInMilliseconds = 0
    const videoContextForOffscreenRendering = new VideoContext(
      canvasForRenderingVideo,
      undefined,
      USE_CANVAS_RECORDING_FOR_VIDEO_RENDERING
        ? undefined
        : { manualUpdate: true }
    )
    return this.renderSequencer(true, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible)
      .then(
        function () {
          finalBufferDurationInMilliseconds = this._calculateDurationInMilliseconds(
            sequencerNodeToStartFrom,
            null,
            sequencerNodeToEndOn,
            includeDeletedSegmentsWhenPossible
          )
          const offlineCtx = new OfflineAudioContext(
            this._maxNumberOfChannels,
            this.getAudioContext().sampleRate *
              (finalBufferDurationInMilliseconds / 1000),
            this.getAudioContext().sampleRate
          )
          return this._scheduleAudioNodeToContext(
            sequencerNodeToStartFrom,
            null,
            sequencerNodeToEndOn,
            null,
            offlineCtx,
            null,
            null,
            videoContextForOffscreenRendering,
            true,
            shouldSequencerNodeBePlayedValidatorFunction,
            includeDeletedSegmentsWhenPossible
          )
        }.bind(this)
      )
      .then(
        function (mergedNodeSchedulingInfoArray) {
        const ffmpegParamsDataToRun = AudioBufferHelper.getInstance().createFFMPEGParamsToRenderVideoByContatenation(
            mergedNodeSchedulingInfoArray,
            audioUrl,
            srtFileUrl, null,null,null, 
            this.getSequencer().getSequencerSettings().getVideoResolution().width,
            this.getSequencer().getSequencerSettings().getVideoResolution().height,
            true)
          return ffmpegParamsDataToRun
        }.bind(this)
      )
  },

  createFFMPEGParamsByContatenationForServerRendering: async function (
    sequencerNodeToStartFrom,
    sequencerNodeToEndOn,
    shouldSequencerNodeBePlayedValidatorFunction,
    maxDurationInMilliseconds,
    audioUrl, 
    includeDeletedSegmentsWhenPossible
  ) {
    const canvasForRenderingVideo = document.createElement('canvas')
    let finalBufferDurationInMilliseconds = 0
    const videoContextForOffscreenRendering = new VideoContext(
      canvasForRenderingVideo,
      undefined,
      USE_CANVAS_RECORDING_FOR_VIDEO_RENDERING
        ? undefined
        : { manualUpdate: true }
    )
    const arrayOfFFMPEGCommands = [];
    let maxSnippetDurationInSeconds  = await this.getSequencer().getAudioBufferCache().getVideoMaxSizeDurationInMillisecondsForRendering();
    if(!maxSnippetDurationInSeconds){
      maxSnippetDurationInSeconds = 60 * 2;// 2minutes in seconds
    }
    await this.renderSequencer(true, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
     
          finalBufferDurationInMilliseconds = this._calculateDurationInMilliseconds(
            sequencerNodeToStartFrom,
            null,
            sequencerNodeToEndOn,
            includeDeletedSegmentsWhenPossible
          );
          const arrayOfSegmentToConcatenate  = this._getArrayOfSequencerNodesBrokenDown(  sequencerNodeToStartFrom,
            null,
            sequencerNodeToEndOn, 
            maxDurationInMilliseconds > 0? maxDurationInMilliseconds: maxSnippetDurationInSeconds * 1000);
          const offlineCtx = new OfflineAudioContext(
            this._maxNumberOfChannels,
            this.getAudioContext().sampleRate *
              (finalBufferDurationInMilliseconds / 1000),
            this.getAudioContext().sampleRate
          )

          for(var i =0; i < arrayOfSegmentToConcatenate.length; i++){
            const startAndEndSequencerNode = arrayOfSegmentToConcatenate[i];
            const segmentStartTimestampInMilliseconds = startAndEndSequencerNode[2];
            const segmentEndTimestampInMilliseconds = startAndEndSequencerNode[3];
            const mergedNodeSchedulingInfoArray = await this._scheduleAudioNodeToContext(
              startAndEndSequencerNode[0],
              null,
              startAndEndSequencerNode[1],
              null,
              offlineCtx,
              null,
              null,
              videoContextForOffscreenRendering,
              true,
              shouldSequencerNodeBePlayedValidatorFunction,
              includeDeletedSegmentsWhenPossible
            );
            if(mergedNodeSchedulingInfoArray){
              const ffmpegParamsDataToRun = AudioBufferHelper.getInstance().createFFMPEGParamsToRenderVideoByContatenation(
                mergedNodeSchedulingInfoArray,
                audioUrl,
                null,
                true,
                segmentStartTimestampInMilliseconds/1000,
                segmentEndTimestampInMilliseconds/1000,
                this.getSequencer().getSequencerSettings().getVideoResolution().width,
                this.getSequencer().getSequencerSettings().getVideoResolution().height,
                true

              );
              arrayOfFFMPEGCommands.push(ffmpegParamsDataToRun);
            }
        }
        return arrayOfFFMPEGCommands;
  },

  getRenderedAudioBlob: function (
    renderWavFile,
    asVideo,
    progressReportFunction,
    alreadyRenderedAudioWAVBlob,
    shouldSequencerNodeBePlayedValidatorFunction
  ) {
    return this.getRenderedAudioBlobClip(
      renderWavFile,
      true,
      null,
      null,
      asVideo,
      progressReportFunction,
      alreadyRenderedAudioWAVBlob,
      shouldSequencerNodeBePlayedValidatorFunction
    )
  },

  getRenderedAudioBlobClip: function (
    renderWavFile,
    applyMasterProcessing,
    sequencerNodeToStartFrom,
    sequencerNodeToEndOn,
    asVideo,
    progressReportFunction,
    alreadyRenderedAudioWAVBlob,
    shouldSequencerNodeBePlayedValidatorFunction,
    includeDeletedSegmentsWhenPossible
  ) {
    this.stopPlayingRenderedBuffer()
    this.set('playbackStopped', false)
    this.getSequencer().setRenderingBeingCreated(true)
    let offlineCtx = null
    const startTimeRenderingForBlob = new Date().getTime()
    const canvasForRenderingVideo = document.createElement('canvas')
    let offlineContextStartRendering = false
    const useFFmpegForVideRendering = USE_FFMPEG_FOR_VIDEO_RENDERING
    if (
      alreadyRenderedAudioWAVBlob &&
      !Utils.getInstance().isSharedArrayBufferSupported()
    ) {
      alreadyRenderedAudioWAVBlob = null
    }

    if (progressReportFunction) {
      if (asVideo) {
        progressReportFunction(
          null,
          window.getI18n(ti18n, 'RENDERING_VIDEO_FILE')
        )
      } else {
        progressReportFunction(
          null,
          renderWavFile
            ? window.getI18n(ti18n, 'RENDERING_AUDIO_UNCOMPRESSED')
            : window.getI18n(ti18n, 'RENDERING_AUDIO')
        )
      }
    }
    const videoContextForOffscreenRendering = asVideo? new VideoContext(
      canvasForRenderingVideo,
      undefined,
      USE_CANVAS_RECORDING_FOR_VIDEO_RENDERING
        ? undefined
        : { manualUpdate: true }
    ): null;
    let audioRenderingResults = null
    if (window.trebbleAnalyticsHelper) {
      window.trebbleAnalyticsHelper.trackEvent(
        'SequencerRendererFileRendering',
        'MediaFileRenderingStarted',
        'Media file rendering started'
      )
    }
    let finalBufferDurationInMilliseconds = 0
    let mergedNodeSchedulingInfoArray = null;
    let audioRenderingCompleted = false;
    return this.renderSequencer(true, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible)
      .then(
        function () {
          finalBufferDurationInMilliseconds = this._calculateDurationInMilliseconds(
            sequencerNodeToStartFrom,
            null,
            sequencerNodeToEndOn,
            includeDeletedSegmentsWhenPossible
          )
          offlineCtx = new OfflineAudioContext(
            this._maxNumberOfChannels,
            this.getAudioContext().sampleRate *
              (finalBufferDurationInMilliseconds / 1000),
            this.getAudioContext().sampleRate
          )
          return this._scheduleAudioNodeToContext(
            sequencerNodeToStartFrom,
            null,
            sequencerNodeToEndOn,
            null,
            offlineCtx,
            null,
            null,
            asVideo ? videoContextForOffscreenRendering : null,
            true,
            shouldSequencerNodeBePlayedValidatorFunction,
            includeDeletedSegmentsWhenPossible
          )
        }.bind(this)
      )
      .then(
        function (mergedNodeSchedulingArray) {
          mergedNodeSchedulingInfoArray = mergedNodeSchedulingArray
          offlineContextStartRendering = true
          /*if(useFFmpegForVideRendering && this._numberOfMergedVideoNodes > MAX_NUMBER_OF_VIDEOS_TO_PROCESS_AT_ONCE_VIA_FFMPEG){
          useFFmpegForVideRendering = false;
        }*/
          if (
            progressReportFunction &&
            !asVideo &&
            !alreadyRenderedAudioWAVBlob
          ) {
            const updateProgressFromOfflineContextRendering = function () {
              if (progressReportFunction && !audioRenderingCompleted) {
                let progress =
                  (offlineCtx.currentTime * 100) /
                  (offlineCtx.length / offlineCtx.sampleRate)
                if (progress > 100) {
                  progress = 100
                }
                progressReportFunction(
                  progress,
                  renderWavFile
                    ? window.getI18n(ti18n, 'RENDERING_AUDIO_UNCOMPRESSED')
                    : window.getI18n(ti18n, 'RENDERING_AUDIO')
                )
                if (offlineContextStartRendering) {
                  window.requestAnimationFrame(
                    updateProgressFromOfflineContextRendering.bind(this)
                  )
                } else {
                  progressReportFunction(
                    null,
                    renderWavFile
                      ? window.getI18n(ti18n, 'RENDERING_AUDIO_UNCOMPRESSED')
                      : window.getI18n(ti18n, 'RENDERING_AUDIO')
                  )
                }
              }
            }
            window.requestAnimationFrame(
              updateProgressFromOfflineContextRendering.bind(this)
            )
          }
          if (!alreadyRenderedAudioWAVBlob) {
            return offlineCtx.startRendering();
          }
        }.bind(this)
      )
      .then(
        function (finalAudioBuffer) {
          audioRenderingCompleted = true;
          if (!alreadyRenderedAudioWAVBlob) {
            offlineContextStartRendering = false
            progressReportFunction(
              null,
              renderWavFile
                ? window.getI18n(
                    ti18n,
                    'NORMALIZING_VOLUME_AUDIO_UNCOMPRESSED'
                  )
                : window.getI18n(ti18n, 'NORMALIZING_VOLUME_AUDIO')
            )
            return this.getSequencer().getAudioBufferCache().ebuNormalization(
              finalAudioBuffer,
              this.getSequencer()
                .getSequencerSettings()
                .getMasterEBULoudnessNormalization()
            )
          }
        }.bind(this)
      )
      .then(
        function (finalAudioBufferWithEBUNorm) {
          if (!alreadyRenderedAudioWAVBlob) {
            let audioFileRenderingProgress ;
            if (progressReportFunction && !asVideo) {
               audioFileRenderingProgress = function (progress) {
                progressReportFunction(
                  progress,
                  renderWavFile
                    ? window.getI18n(ti18n, 'ENCODING_AUDIO_UNCOMPRESSED')
                    : window.getI18n(ti18n, 'ENCODING_AUDIO')
                )
              }
            }
            return this.getSequencer().getAudioBufferCache().export(
              finalAudioBufferWithEBUNorm,
              renderWavFile ? 'audio/wav' : 'audio/mpeg',
              audioFileRenderingProgress
            )
          } else {
            if (renderWavFile) {
              return {
                url: URL.createObjectURL(alreadyRenderedAudioWAVBlob),
                blob: alreadyRenderedAudioWAVBlob
              }
            } else {
              let audioFileRenderingProgress;
              if (progressReportFunction) {
                 audioFileRenderingProgress = function (progress) {
                  progressReportFunction(
                    progress,
                    window.getI18n(ti18n, 'ENCODING_AUDIO')
                  )
                }
              }
              if (
                false &&
                window.chrome &&
                !window.mobileAndTabletcheck() &&
                AudioBufferHelper.getInstance().isFFmpegLibraryAvailable()
              ) {
                return this.getSequencer().getAudioBufferCache()
                  .convertToMp3(
                    alreadyRenderedAudioWAVBlob,
                    audioFileRenderingProgress
                  )
                  .then(
                    function (audioMp3Blob) {
                      return {
                        url: URL.createObjectURL(audioMp3Blob),
                        blob: audioMp3Blob
                      }
                    }.bind(this)
                  )
              } else {
                return this.getSequencer().getAudioBufferCache()
                  .getAudioBufferFromBlobWithoutCaching(
                    URL.createObjectURL(alreadyRenderedAudioWAVBlob)
                  )
                  .then((function (audioBufferWav) {
                    return this.getSequencer().getAudioBufferCache().export(
                      audioBufferWav,
                      'audio/mpeg',
                      audioFileRenderingProgress
                    )
                  }).bind(this))
              }
            }
          }
        }.bind(this)
      )
      .then(
        function (result) {
          audioRenderingResults = result
          this.getSequencer().setRenderingBeingCreated(false)
          console.log(
            'Time to render blob file  ' +
              (new Date().getTime() - startTimeRenderingForBlob)
          )
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackTiming(
              'Time to render blob file for sequencer renderer',
              new Date().getTime() - startTimeRenderingForBlob
            )
          }
          if (asVideo) {
            const framePerSeconds = 24
            let audioFileRenderingProgress = null
            if (progressReportFunction) {
              audioFileRenderingProgress = function (progress) {
                progressReportFunction(
                  progress,
                  window.getI18n(ti18n, 'RENDERING_VIDEO_FILE')
                )
              }
            }

            if (!useFFmpegForVideRendering) {
              const videoRenderingFunction = USE_CANVAS_RECORDING_FOR_VIDEO_RENDERING
                ? this._renderVideoFileUsingContext
                : this._renderVideoFileUsingContextAndWebCodec.bind(this)
              return videoRenderingFunction(
                videoContextForOffscreenRendering,
                framePerSeconds,
                audioFileRenderingProgress,
                audioRenderingResults.url
              )
            } else {
              //const videoRenderingFunction = RENDER_USING_FFMPEG_OVERLAY?  this.getSequencer().getAudioBufferCache().renderVideoByConcatenatingVideoUsingFFmpegUsingOverlay.bind(this.getSequencer().getAudioBufferCache()) : this.getSequencer().getAudioBufferCache().renderVideoByConcatenatingVideoUsingFFmpegAndContatenation.bind(this.getSequencer().getAudioBufferCache());
              //return videoRenderingFunction(videoContextForOffscreenRendering, audioRenderingResults.blob, audioFileRenderingProgress, this.getSrtTextForSelectedNodes(false));
              return this.getSequencer().getAudioBufferCache().renderVideoFileInBatchIfApplicable(
                mergedNodeSchedulingInfoArray,
                alreadyRenderedAudioWAVBlob
                  ? alreadyRenderedAudioWAVBlob
                  : audioRenderingResults.blob,
                audioFileRenderingProgress,
                this.getSrtTextForSelectedNodes(
                  false,
                  mergedNodeSchedulingInfoArray
                )
              )
            }
          }
        }.bind(this)
      )
      .then(
        function (webmVideoBlob) {
          if (!useFFmpegForVideRendering) {
            let audioVideoMixingRenderingProgress = null
            if (webmVideoBlob) {
              if (progressReportFunction) {
                audioVideoMixingRenderingProgress = function (progress) {
                  progressReportFunction(
                    progress,
                    window.getI18n(ti18n, 'MIXING_AUDIO_AND_VIDEO')
                  )
                }
              }
              return USE_CANVAS_RECORDING_FOR_VIDEO_RENDERING
                ? webmVideoBlob
                : this.getSequencer().getAudioBufferCache().convertStreams(
                    null,
                    audioRenderingResults.blob,
                    audioVideoMixingRenderingProgress,
                    this.getSrtTextForSelectedNodes(
                      false,
                      mergedNodeSchedulingInfoArray
                    ),
                    webmVideoBlob
                  )
            }
          }
          return webmVideoBlob
        }.bind(this)
      )
      .then(
        function (mergedAudioAndVideoBlob) {
          if (videoContextForOffscreenRendering) {
            videoContextForOffscreenRendering.reset()
          }
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              'SequencerRendererFileRendering',
              'MediaFileRenderingCompleted',
              'Media file rendering completed'
            )
          }
          this._disconnectArrayOfMergeNodes(mergedNodeSchedulingInfoArray)
          if (offlineCtx.state === 'suspended') {
            //Offline context was not used so start rendering to clean up the memory
            offlineCtx.startRendering()
          }
          if (mergedAudioAndVideoBlob) {
            return mergedAudioAndVideoBlob
          } else {
            return audioRenderingResults.blob
          }
        }.bind(this)
      )
      .catch(
        function (error) {
          offlineContextStartRendering = false
          if (mergedNodeSchedulingInfoArray) {
            this._disconnectArrayOfMergeNodes(mergedNodeSchedulingInfoArray)
          }
          if (offlineCtx.state === 'suspended') {
            //Offline context was not used so start rendering to clean up the memory
            offlineCtx.startRendering()
          }
          this.getSequencer().setRenderingBeingCreated(false)
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              'SequencerRendererFileRendering',
              'MediaFileRenderingFailed',
              'Media file rendering failed',
              null,
              error
            )
          }
          window.sendErrorToRaygun(error, true)
          throw error
        }.bind(this)
      )
  },

  _renderVideoFileUsingContextViaCanvasCapture: function (
    videoContextForRendering,
    framePerSeconds,
    progressReportFunction,
    audioUrl
  ) {
    return new RSVP.Promise(function (resolve, reject) {
      const videoStream = videoContextForRendering.element.captureStream()
      let mediaRecorder = null
      let recordedBlobs = null
      if (audioUrl) {
        const audioNode = videoContextForRendering.audio(audioUrl)
        audioNode.connect(videoContextForRendering.destination)
        audioNode.start(0)
        audioNode.stop(videoContextForRendering.duration)
      }
      const handleDataAvailable = function (event) {
        if (event.data && event.data.size > 0 && recordedBlobs) {
          recordedBlobs.push(event.data)
        }
      }
      const handleStop = function (event) {
        const superBuffer = new Blob(recordedBlobs, { type: 'video/webm' })
        resolve(superBuffer)
      }
      const onVideoContextNewFrameRendered = function () {
        if (!mediaRecorder) {
          let options = { mimeType: 'video/webm' }
          recordedBlobs = []
          try {
            mediaRecorder = new MediaRecorder(videoStream, options)
          } catch (e0) {
            console.log(
              'Unable to create MediaRecorder with options Object: ',
              e0
            )
            try {
              options = { mimeType: 'video/webm,codecs=vp9' }
              mediaRecorder = new MediaRecorder(videoStream, options)
            } catch (e1) {
              console.log(
                'Unable to create MediaRecorder with options Object: ',
                e1
              )
              try {
                options = 'video/vp8' // Chrome 47
                mediaRecorder = new MediaRecorder(videoStream, options)
              } catch (e2) {
                alert(
                  'MediaRecorder is not supported by this browser.\n\n' +
                    'Try Firefox 29 or later, or Chrome 47 or later, ' +
                    'with Enable experimental Web Platform features enabled from chrome://flags.'
                )
                console.error('Exception while creating MediaRecorder:', e2)
                return
              }
            }
          }
          mediaRecorder.onstop = handleStop
          mediaRecorder.ondataavailable = handleDataAvailable
          mediaRecorder.start()
        } else {
          if (mediaRecorder.state === 'paused') {
            mediaRecorder.resume()
          }
          if (mediaRecorder.state === 'inactive') {
            mediaRecorder.start()
          }
        }
      }

      const updateProgressFunctionId = setInterval(function () {
        if (progressReportFunction && mediaRecorder) {
          progressReportFunction(
            (videoContextForRendering.currentTime /
              videoContextForRendering.duration) *
              100
          )
        }
      }, 2)

      const onVideoContextStalled = function () {
        if (mediaRecorder) {
          mediaRecorder.pause()
        }
      }

      const onVideoContextEnded = function () {
        if (mediaRecorder) {
          mediaRecorder.stop()
          clearInterval(updateProgressFunctionId)
        }
      }
      videoContextForRendering.registerCallback(
        VideoContext.EVENTS.STALLED,
        onVideoContextStalled
      )
      videoContextForRendering.registerCallback(
        VideoContext.EVENTS.ENDED,
        onVideoContextEnded
      )
      videoContextForRendering.registerCallback(
        VideoContext.EVENTS.UPDATE,
        onVideoContextNewFrameRendered
      )
      videoContextForRendering.play()
    })
  },

  /*_renderVideoFileUsingContext: function (
    videoContextForRendering,
    framePerSeconds,
    progressReportFunction
  ) {
    return new RSVP.Promise(
      function (resolve, reject) {
        const startTimeRenderingForBlob = new Date().getTime()
        const frameDuration = 1 / framePerSeconds
        const videoWriter = new WebMWriter({
          quality: 1,
          frameRate: framePerSeconds
        })

        const useWebworker = false
        const webWorker = null

        function nodesReady () {
          for (const i in videoContextForRendering._sourceNodes) {
            if (
              videoContextForRendering._sourceNodes[i]._startTime <=
                videoContextForRendering.currentTime &&
              videoContextForRendering._sourceNodes[i]._stopTime >=
                videoContextForRendering.currentTime
            ) {
              if (
                !videoContextForRendering._sourceNodes[i]._ready &&
                videoContextForRendering._sourceNodes[i].state < 4
              )
                return false
            }
          }
          return true
        }

        const webWorkerString =
          "\
                  const running = false;\
                  function tick(){\
                  postMessage(Date.now());\
                  if (running){\
                      setTimeout(tick, 1000/20);\
              }\
          }\
          self.addEventListener('message',function(msg){\
          const data = msg.data;\
          if (data === 'start'){\
              running = true;\
          tick();\
      }\
      if (data === 'stop') running = false;\
  });"

        const initWebWorker = function () {
          window.URL = window.URL || window.webkitURL
          const blob = new Blob([webWorkerString], {
            type: 'application/javascript'
          })
          webWorker = new Worker(URL.createObjectURL(blob))
          webWorker.onmessage = function(msg){
            const time = msg.data
            update(time)
          }
        }

        const onVisibilityChange = function () {
          if (document.hidden === true) {
            lostVisibility()
          } else {
            gainedVisibility()
          }
        }

        const gainedVisibility = function () {
          useWebworker = false
          if (webWorker) webWorker.postMessage('stop')
          requestAnimationFrame(update)
        }.bind(this)

        const lostVisibility = function () {
          useWebworker = true
          if (!webWorker) {
            initWebWorker()
          }
          webWorker.postMessage('start')
        }.bind(this)

        const timeLastRenderedFrame = new Date().getTime()

        function update () {
          // when all required sources are ready (so we "catch" the render)
          if (nodesReady()) {
            //console.log("time since last frame "+( new Date().getTime() -timeLastRenderedFrame));
            timeLastRenderedFrame = new Date().getTime()
            // this update call will render
            videoContextForRendering.update(0)
            // store the frame some how
            videoWriter.addFrame(videoContextForRendering.element)
            // seek the next frame
            videoContextForRendering.currentTime += frameDuration
          }

          // on load or when we seek the frame it loads the sources, otherwise poll for source status
          videoContextForRendering.update(0)

          if (
            videoContextForRendering.currentTime <
            videoContextForRendering.duration
          ) {
            // this way skips the last frame
            // loop until the end (provably its better using a setTimeout)
            if (progressReportFunction) {
              progressReportFunction(
                (videoContextForRendering.currentTime /
                  videoContextForRendering.duration) *
                  100
              )
            }
            if (!useWebworker) {
              requestAnimationFrame(update)
            }
            //setTimeout(update);
          } else {
            // then store the result
            videoWriter.complete().then(function (blob) {
              console.log(
                'Time to render video blob file with no sound ' +
                  (new Date().getTime() - startTimeRenderingForBlob)
              )
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackTiming(
                  'Time to render video blob file webm for sequencer renderer',
                  new Date().getTime() - startTimeRenderingForBlob
                )
              }
              resolve(blob)
              if(webWorker){
                webWorker.terminate();
              }
              if (typeof document.hidden === 'undefined') {
                window.removeEventListener('focus', gainedVisibility)
                window.removeEventListener('blur', lostVisibility)
              } else {
                //Otherwise we can use the visibility API to do the loose/gain focus properly
                document.removeEventListener(
                  'visibilitychange',
                  onVisibilityChange,
                  false
                )
              }
            })
          }
        }

        //If page visibility API not present fallback to using "focus" and "blur" event listeners.
        if (typeof document.hidden === 'undefined') {
          window.addEventListener('focus', gainedVisibility)
          window.addEventListener('blur', lostVisibility)
        } else {
          //Otherwise we can use the visibility API to do the loose/gain focus properly
          document.addEventListener(
            'visibilitychange',
            onVisibilityChange,
            false
          )
        }

        requestAnimationFrame(update)

        // start the loop
        update()
      }.bind(this)
    )
  },*/

  chunksToUint8Array: function (encodedVideoChunks) {
    let totalLength = 0
    encodedVideoChunks.forEach(function(chunk){
      totalLength += chunk.byteLength // assuming byteLength gives the length of each chunk
    })

    const allChunks = new Uint8Array(totalLength)
    let offset = 0
    encodedVideoChunks.forEach(function(chunk){
      allChunks.set(new Uint8Array(chunk.buffer), offset) // assuming buffer gives the ArrayBuffer of each chunk
      offset += chunk.byteLength
    })

    return allChunks
  },

  _renderVideoFileUsingContextAndWebCodec: function (
    videoContextForRendering,
    framePerSeconds,
    progressReportFunction,
    audioBlobUrl,
    audioBlob,
    srtTextFileContent
  ) {
    return new RSVP.Promise(
      function (resolve, reject) {
        const startTimeRenderingForBlob = new Date().getTime()
        const chunks = []
        const handleChunk = function (chunk) {
          chunks.push(chunk)
        }
        const init = {
          output: handleChunk,
          error: function (e) {
            console.log(e.message)
          }
        }
        const config = {
          codec: 'avc1.420034',
          avc: { format: 'annexb' },
          width: videoContextForRendering.element.width,
          height: videoContextForRendering.element.height,
          hardwareAccelaration: 'prefer-hardware',
          latencyMode: 'quality',
          bitrate: 2_000_000, // 2 Mbps
          framerate: framePerSeconds
        }
        const videoEncoder = new window.VideoEncoder(init)
        videoEncoder.configure(config)
        const frameDuration = 1 / framePerSeconds

        let useWebworker = false
        let webWorker = null

        function nodesReady () {
          for (const i in videoContextForRendering._sourceNodes) {
            if (
              videoContextForRendering._sourceNodes[i]._startTime <=
                videoContextForRendering.currentTime &&
              videoContextForRendering._sourceNodes[i]._stopTime >=
                videoContextForRendering.currentTime
            ) {
              if (
                !videoContextForRendering._sourceNodes[i]._ready &&
                videoContextForRendering._sourceNodes[i].state < 4
              )
                return false
            }
          }
          return true
        }

        const webWorkerString =
          "\
      const running = false;\
      function tick(){\
      postMessage(Date.now());\
      if (running){\
          setTimeout(tick, 1000/20);\
  }\
}\
self.addEventListener('message',function(msg){\
const data = msg.data;\
if (data === 'start'){\
  running = true;\
tick();\
}\
if (data === 'stop') running = false;\
});"

        const initWebWorker = function () {
          window.URL = window.URL || window.webkitURL
          const blob = new Blob([webWorkerString], {
            type: 'application/javascript'
          })
          webWorker = new Worker(URL.createObjectURL(blob))
          webWorker.onmessage = function(msg) {
            const time = msg.data
            update(time)
          }
        }

        const onVisibilityChange = function () {
          if (document.hidden === true) {
            lostVisibility()
          } else {
            gainedVisibility()
          }
        }

        const gainedVisibility = function () {
          useWebworker = false
          if (webWorker) webWorker.postMessage('stop')
          requestAnimationFrame(update)
        }.bind(this)

        const lostVisibility = function () {
          useWebworker = true
          if (!webWorker) {
            initWebWorker()
          }
          webWorker.postMessage('start')
        }.bind(this)

        let timeLastRenderedFrame = new Date().getTime()

        function update () {
          // when all required sources are ready (so we "catch" the render)
          if (nodesReady()) {
            //console.log("time since last frame "+( new Date().getTime() -timeLastRenderedFrame));
            timeLastRenderedFrame = new Date().getTime()
            // this update call will render
            videoContextForRendering.update(0)
            // store the frame some how
            const videoFrame = new window.VideoFrame(
              videoContextForRendering.element,
              { timestamp: videoContextForRendering.currentTime * 1000000 }
            )
            videoEncoder.encode(videoFrame)
            videoFrame.close()
            // seek the next frame
            videoContextForRendering.currentTime += frameDuration
          }

          // on load or when we seek the frame it loads the sources, otherwise poll for source status
          videoContextForRendering.update(0)

          if (
            videoContextForRendering.currentTime <
            videoContextForRendering.duration
          ) {
            // this way skips the last frame
            // loop until the end (provably its better using a setTimeout)
            if (progressReportFunction) {
              progressReportFunction(
                (videoContextForRendering.currentTime /
                  videoContextForRendering.duration) *
                  100
              )
            }
            if (!useWebworker) {
              requestAnimationFrame(update.bind(this))
            }
            //setTimeout(update);
          } else {
            // then store the result
            videoEncoder.flush().then(
              function () {
                videoEncoder.close()
                const videoData = this.chunksToUint8Array(chunks)
                /*	return this.getSequencer().getAudioBufferCache().convertStreams(null, audioBlob, progressReportFunction, srtTextFileContent, videoData) ;
}.bind(this)).then((function(blob){*/
                console.log(
                  'Time to render video blob file with no sound ' +
                    (new Date().getTime() - startTimeRenderingForBlob)
                )
                if (window.trebbleAnalyticsHelper) {
                  window.trebbleAnalyticsHelper.trackTiming(
                    'Time to render video blob file webm for sequencer renderer',
                    new Date().getTime() - startTimeRenderingForBlob
                  )
                }
                resolve(videoData)
                if(webWorker){
                  webWorker.terminate();
                }
                if (typeof document.hidden === 'undefined') {
                  window.removeEventListener('focus', gainedVisibility)
                  window.removeEventListener('blur', lostVisibility)
                } else {
                  //Otherwise we can use the visibility API to do the loose/gain focus properly
                  document.removeEventListener(
                    'visibilitychange',
                    onVisibilityChange,
                    false
                  )
                }
                return videoData
              }.bind(this)
            )
          }
        }

        //If page visibility API not present fallback to using "focus" and "blur" event listeners.
        if (typeof document.hidden === 'undefined') {
          window.addEventListener('focus', gainedVisibility)
          window.addEventListener('blur', lostVisibility)
        } else {
          //Otherwise we can use the visibility API to do the loose/gain focus properly
          document.addEventListener(
            'visibilitychange',
            onVisibilityChange,
            false
          )
        }

        requestAnimationFrame(update.bind(this))

        // start the loop
        update.call(this)
      }
        .bind(this)
        .bind(this)
    )
  },

  _reinitialize: function () {
    return this._initialize()
  },

  _getAudioType: function (node) {
    if (Utils.getInstance().isWordSequencerNodeInstance(node)) {
      return AudioBufferHelper.getInstance().getAudioTypes().VOICE
    }
    if (Utils.getInstance().isPauseSequencerNodeInstance(node)) {
      return AudioBufferHelper.getInstance().getAudioTypes().VOICE
    }
    if (Utils.getInstance().isPunctuationSequencerNodeInstance(node)) {
      return AudioBufferHelper.getInstance().getAudioTypes().VOICE
    }
    if (Utils.getInstance().isEndMusicWrapSequencerNodeInstance(node)) {
      return AudioBufferHelper.getInstance().getAudioTypes().WRAP
    }

    if (Utils.getInstance().isStartMusicWrapSequencerNodeInstance(node)) {
      return AudioBufferHelper.getInstance().getAudioTypes().WRAP
    }

    if (Utils.getInstance().isAudioSequencerNodeInstance(node)) {
      return AudioBufferHelper.getInstance().getAudioTypes().INSERT
    }

    if (Utils.getInstance().isVideoSequencerNodeInstance(node)) {
      return AudioBufferHelper.getInstance().getAudioTypes().INSERT
    }
    return null
  },

  _loadAllMasterAudioBuffersInAudioBufferHelper: function () {
    const audioUrlsToLoad = []
    const audioUrlsToAudioTypes = {}
    let node = this._sequencer.getFirstNode()
    const promises = []
    while (node) {
      const audioSegment = node.getAudioSegment()
      if (audioSegment) {
        const audioUrl = audioSegment.getAudioUrl()
        if (audioUrl) {
          if (audioUrlsToLoad.indexOf(audioUrl) == -1) {
            audioUrlsToLoad.push(audioUrl)
          }
          if (!audioUrlsToAudioTypes[audioUrl]) {
            audioUrlsToAudioTypes[audioUrl] = []
          }
          const audioType = this._getAudioType(node)
          if (audioType) {
            if (audioUrlsToAudioTypes[audioUrl].indexOf(audioType) == -1) {
              audioUrlsToAudioTypes[audioUrl].push(audioType)
            }
          }
        }
      }
      node = node.getNext()
    }
    for (let audioUrl in audioUrlsToAudioTypes) {
      const audioTypes = audioUrlsToAudioTypes[audioUrl]
      const audioId = audioUrl
      this._audioUrlToAudioId[audioUrl] = audioId
      if (audioTypes.length > 0) {
        for (let i = 0; i < audioTypes.length; i++) {
          const audioType = audioTypes[i]
          const audioIdWithAudioTypeAndSettings = this.getSequencer().getAudioBufferCache().getAudioUniqueIdWithAudioTypeId(
            audioId,
            audioType,
            this.getSequencer().getSequencerSettings()
          )
          const p = this.getSequencer().getAudioBufferCache()
            .loadAudioBufferFromUrl(
              audioUrl,
              audioId,
              audioType,
              this.getSequencer().getSequencerSettings()
            )
            .then(
              function (audioBuffer) {
                if (
                  audioBuffer.numberOfChannels >
                  this.self._maxNumberOfChannels
                ) {
                  this.self._maxNumberOfChannels =
                    audioBuffer.numberOfChannels
                }
                this.self._audioIdWithAudioTypeAndSettingToAudioBuffer[
                  this.audioIdWithAudioTypeAndSettings
                ] = audioBuffer
                return audioBuffer
              }.bind({
                self: this,
                audioIdWithAudioTypeAndSettings: audioIdWithAudioTypeAndSettings
              })
            )
          promises.push(p)
        }
      } else {
        const audioIdWithAudioTypeAndSettings = this.getSequencer().getAudioBufferCache().getAudioUniqueIdWithAudioTypeId(
          audioId,
          null,
          this.getSequencer().getSequencerSettings()
        )
        const p = this.getSequencer().getAudioBufferCache()
          .loadAudioBufferFromUrl(
            audioUrl,
            audioId,
            null,
            this._sequencer.getSequencerSettings()
          )
          .then(
            function (audioBuffer) {
              if (
                audioBuffer.numberOfChannels > this.self._maxNumberOfChannels
              ) {
                this.self._maxNumberOfChannels = audioBuffer.numberOfChannels
              }
              this.self._audioIdWithAudioTypeAndSettingToAudioBuffer[
                this.audioIdWithAudioTypeAndSettings
              ] = audioBuffer
              return audioBuffer
            }.bind({
              self: this,
              audioIdWithAudioTypeAndSettings: audioIdWithAudioTypeAndSettings
            })
          )
        promises.push(p)
      }
    }
    return Promise.all(promises)
  },

  _getAudioBufferForAudioUrlFromCache: function (audioUrl, audioType) {
    const audioId = this._audioUrlToAudioId[audioUrl]
    const audioIdWithAudioTypeAndSettings = this.getSequencer().getAudioBufferCache().getAudioUniqueIdWithAudioTypeId(
      audioId,
      audioType,
      this.getSequencer().getSequencerSettings()
    )
    return this._audioIdWithAudioTypeAndSettingToAudioBuffer[
      audioIdWithAudioTypeAndSettings
    ]
  },

  _getCrossafeInMillisecondsAtTheBeginnnig: function (sequencerNode) {
    if (
      sequencerNode.isThereEditBoundaryAtBeginningAndIsSegmentLargeEnoughForCrossfade()
    ) {
      const crossfadeDuratioInMillisecondAtBeginning = sequencerNode.getApplyCrossfadeAtTheBeginingDurationInMilliseconds()
      return crossfadeDuratioInMillisecondAtBeginning
        ? crossfadeDuratioInMillisecondAtBeginning
        : 0
    } else {
      return 0
    }
  },

  _getCrossafeInMillisecondsAtTheEnd: function (sequencerNode) {
    if (
      sequencerNode.isThereEditBoundaryAtEndAndIsSegmentLargeEnoughForCrossfade()
    ) {
      const crossfadeDuratioInMillisecondAtEnd = sequencerNode.getApplyCrossfadeAtTheEndDurationInMilliseconds()
      return crossfadeDuratioInMillisecondAtEnd
        ? crossfadeDuratioInMillisecondAtEnd
        : 0
    } else {
      return 0
    }
  },

  _getDefaultNetDurationWithoutCrossfadeInMilliseconds: function (
    sequencerNode
  ) {
    const audioSegment = sequencerNode.getAudioSegment()
    const audioType = this._getAudioType(sequencerNode)
    if (audioSegment) {
      const audioUrl = audioSegment.getAudioUrl()
      if (audioUrl) {
        let startTimeInMilliseconds = audioSegment.getStartTime()
        let endTimeInMilliseconds = audioSegment.getEndTime()
        const crossafeInMillisecondsAtTheBeginnnig = this._getCrossafeInMillisecondsAtTheBeginnnig(
          sequencerNode
        )
        const crossafeInMillisecondsAtTheEnd = this._getCrossafeInMillisecondsAtTheEnd(
          sequencerNode
        )

        if (
          endTimeInMilliseconds == 0 ||
          (endTimeInMilliseconds > 0 &&
            startTimeInMilliseconds >= 0 &&
            endTimeInMilliseconds - startTimeInMilliseconds == 0)
        ) {
          return 0
        }
        let totalDurationInMilliseconds = null
        let netDurationInMilliseconds = null
        if (
          endTimeInMilliseconds &&
          startTimeInMilliseconds >= 0 &&
          endTimeInMilliseconds - startTimeInMilliseconds > 0
        ) {
          totalDurationInMilliseconds =
            endTimeInMilliseconds +
            crossafeInMillisecondsAtTheEnd -
            startTimeInMilliseconds +
            crossafeInMillisecondsAtTheBeginnnig
          netDurationInMilliseconds =
            endTimeInMilliseconds - startTimeInMilliseconds
        } else {
          const audioBufferSource = this._getAudioBufferForAudioUrlFromCache(
            audioUrl,
            audioType
          )
          startTimeInMilliseconds = 0
          endTimeInMilliseconds = audioBufferSource.duration * 1000 || 0
          totalDurationInMilliseconds =
            endTimeInMilliseconds +
            crossafeInMillisecondsAtTheEnd -
            startTimeInMilliseconds +
            crossafeInMillisecondsAtTheBeginnnig
          netDurationInMilliseconds =
            endTimeInMilliseconds - startTimeInMilliseconds
        }
        return netDurationInMilliseconds
      }
    }
    return 0
  },

  /*
    _createAudioBufferDefault: function(nodeToRender, audioType){
      const audioSegment = nodeToRender.getAudioSegment();
      if(audioSegment){
        const audioUrl = audioSegment.getAudioUrl();					
        if(audioUrl){
          const startTimeInMilliseconds = audioSegment.getStartTime();
          const endTimeInMilliseconds = audioSegment.getEndTime();
          if(endTimeInMilliseconds == 0 || (endTimeInMilliseconds > 0 && startTimeInMilliseconds >= 0 && endTimeInMilliseconds - startTimeInMilliseconds == 0)){
            return RSVP.Promise.resolve(null);
          }
          const promiseRetrieveAudioBuffer  = null;
          if(endTimeInMilliseconds && startTimeInMilliseconds >= 0 && endTimeInMilliseconds - startTimeInMilliseconds > 0){
            promiseRetrieveAudioBuffer = this.getSequencer().getAudioBufferCache().getAudioBufferSlice(this._audioUrlToAudioId[audioUrl], startTimeInMilliseconds - this._getCrossafeInMillisecondsAtTheBeginnnig(nodeToRender) ,endTimeInMilliseconds + this._getCrossafeInMillisecondsAtTheEnd(nodeToRender) , audioType, this.getSequencer().getSequencerSettings());
          }else{
            promiseRetrieveAudioBuffer = this.getSequencer().getAudioBufferCache().getAudioBufferFromUrl(audioUrl, this._audioUrlToAudioId[audioUrl],  audioType, this.getSequencer().getSequencerSettings());
          }
          return promiseRetrieveAudioBuffer;
        }
      }
      return RSVP.Promise.resolve(null);
    },*/

  _getDurationInMillisecondsOfNodeWithCid: function (nodeCidArray) {
    let totalDurationInMilliseconds = 0
    if (nodeCidArray) {
      for (let i = 0; i < nodeCidArray.length; i++) {
        const audioBuffer = this._nodeCidToAudioBuffer[nodeCidArray[i]]
        if (
          audioBuffer &&
          AudioBufferHelper.getInstance().getMaxAudioBufferDuration(
            audioBuffer
          )
        ) {
          totalDurationInMilliseconds =
            totalDurationInMilliseconds +
            AudioBufferHelper.getInstance().getMaxAudioBufferDuration(
              audioBuffer
            ) *
              1000
        }
      }
    }
    return totalDurationInMilliseconds
  },

  /*_createAudioBufferForEndAudioWrapNode: function(nodeToRender){

      const audioSegment = nodeToRender.getAudioSegment();
      if(!audioSegment || !nodeToRender.isCreatingAudioSegmentThatWillBeMergeWithWrappedNodeAudioSegment()){
        return RSVP.Promise.resolve(null);
      }

      const durationInMilliseconds = 0;

      const cidOfWrappedNodes = [];
      const promisesOfNodeThatNeedToBerenderedFirst = [];
      const nodeWrapped = nodeToRender.getStartWrappedNode();
      const audioUrl = audioSegment.getAudioUrl();
      const trimStart = audioSegment.getStartTime();
      const trimEnd = audioSegment.getEndTime();
      const totalDurationInMillisecondsOfContainedWrapNodes = 0;
      while(nodeWrapped){
        if(Utils.getInstance().isWrapSequencerNodeInstance(nodeWrapped)){
          totalDurationInMillisecondsOfContainedWrapNodes = totalDurationInMillisecondsOfContainedWrapNodes + nodeWrapped.getCurrentNodeMargin();
        }else{
          const cid = nodeWrapped.cid;
          cidOfWrappedNodes.push(cid);
          const audioBufferPromise =  this._nodeCidToAudioBufferPromise[cid];
          if(audioBufferPromise){
            promisesOfNodeThatNeedToBerenderedFirst.push(audioBufferPromise);
          }
        }
        if(nodeWrapped == nodeToRender.getEndWrappedNode()){
          nodeWrapped = null;
        }else{
          nodeWrapped = nodeWrapped.getNext();
        }
      }

      return Promise.all(promisesOfNodeThatNeedToBerenderedFirst).then((function(){
        const totalDurationInMillisecondsOfWrappedNodes = this._getDurationInMillisecondsOfNodeWithCid(cidOfWrappedNodes);
        const startPaddingInMilliseconds = nodeToRender.getIntroPaddingInMilliseconds();
        const endPaddingInMilliseconds = nodeToRender.getOutroPaddingInMilliseconds();
        durationInMilliseconds = startPaddingInMilliseconds + totalDurationInMillisecondsOfWrappedNodes + endPaddingInMilliseconds + totalDurationInMillisecondsOfContainedWrapNodes;
        this._endNodeCidToDurationInMilliseconds[nodeToRender.cid] = durationInMilliseconds;
        return this.getSequencer().getAudioBufferCache().getAudioBufferSlice(this._audioUrlToAudioId[audioUrl], trimStart ,trimEnd , AudioBufferHelper.getInstance().getAudioTypes().WRAP, this.getSequencer().getSequencerSettings());
      }).bind(this));


    },*/

  /*_createAudioBuffer : function(sequencerNode){
      const audioBufferRenderingPromise =  null;
      if(Utils.getInstance().isPunctuationSequencerNodeInstance(sequencerNode) 
        || Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNode)
        || Utils.getInstance().isStartMusicWrapSequencerNodeInstance(sequencerNode) 
        || (sequencerNode.getAudioSegment() && Utils.getInstance().isUnsupportedAudioSegmentInstance(sequencerNode.getAudioSegment()))){
        return RSVP.Promise.resolve(null);
    }
    if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNode) || Utils.getInstance().isPauseSequencerNodeInstance(sequencerNode)){
      audioBufferRenderingPromise =  this._createAudioBufferDefault(sequencerNode, AudioBufferHelper.getInstance().getAudioTypes().VOICE);
    }

    if(Utils.getInstance().isAudioSequencerNodeInstance(sequencerNode)){
      audioBufferRenderingPromise =  this._createAudioBufferDefault(sequencerNode, AudioBufferHelper.getInstance().getAudioTypes().INSERT);
    }


    if(Utils.getInstance().isEndMusicWrapSequencerNodeInstance(sequencerNode)){
      audioBufferRenderingPromise =  this._createAudioBufferForEndAudioWrapNode(sequencerNode);
    }


    return audioBufferRenderingPromise.then((function(audioBuffer){
      return audioBuffer;
    }).bind(this))

  },*/

  _getSchedulingNodeInfoForSequencerNode: function (sequencerNode) {
    return sequencerNode && sequencerNode.cid
      ? this._nodeCidToNodeSchedulingInfo[sequencerNode.cid]
      : null
  },

  /*
  _createAudioBufferForEachSequencerNode : function(applyMasterProcessing){
    const nodeToRender = this.getSequencer().getFirstNode();
    const promises = [];
    while(nodeToRender){
      const nodeCid = nodeToRender.cid;
      const audioBufferPromise = this._createAudioBuffer(nodeToRender);
      this._nodeCidToAudioBufferPromise[nodeToRender.cid] = audioBufferPromise.then((function(audioBuffer){
        if(audioBuffer){
          this.self._nodeCidToAudioBuffer[this.nodeCid] = audioBuffer;
        }
      }).bind({"self": this,"nodeCid": nodeToRender.cid}));
      promises.push(audioBufferPromise);
      nodeToRender = nodeToRender.getNext();
    }
    return Promise.all(promises);
  },*/

  _createWrapNodesThatStartedBeforeNode: function (
    sequencerNodeToStartFrom,
    mergedNodes,
    audioContext
  ) {
    const nodeSchedulingInfoOfNodeToStartFrom = this._getSchedulingNodeInfoForSequencerNode(
      sequencerNodeToStartFrom
    )
    if(nodeSchedulingInfoOfNodeToStartFrom){
      const startedWrapNodes = sequencerNodeToStartFrom.getStartWrapNodes()
      startedWrapNodes.map(
        function (startWapNode) {
          const nodeSchedulingInfo = this._getSchedulingNodeInfoForSequencerNode(
            startWapNode
          )
          if (nodeSchedulingInfo) {
            const mergedNode = new MergedIncompleteWrapSequencerNodeSchedulingInfo({sequencer: this.getSequencer()})
            mergedNode.initializeWithStartWrapNode(
              nodeSchedulingInfo,
              nodeSchedulingInfoOfNodeToStartFrom.getStartTimeOffset(),
              audioContext
            )
            mergedNodes.push(mergedNode)
          }
        }.bind(this)
      )
    }
  },

  _getArrayOfSequencerNodesBrokenDown: function (
    sequencerNodeToStartFrom,
    stopAfterMilliseconds,
    sequencerNodeToStopPlayAt, 
    maxDurationInMilliseconds
  ) {
    if (!sequencerNodeToStartFrom) {
      sequencerNodeToStartFrom = this.getSequencer().getFirstNode()
    }

    const arrayOSegmentsToReturn = [];
    let totalBufferDurationInMilliseconds = 0;
    let sequencerNodeToPlay = sequencerNodeToStartFrom;
    let sequencerNodeSegmentStart = sequencerNodeToStartFrom;
    let sequencerNodeSegmentEnd = sequencerNodeToPlay;
    let totalSegmentDurationInMilliseconds = 0;
    let segmentStartTimestampInMilliseconds = 0;
    while (
      sequencerNodeToPlay &&
      (!stopAfterMilliseconds ||
        totalBufferDurationInMilliseconds < stopAfterMilliseconds)
    ) {
      let nodeDuration = 0;
      sequencerNodeSegmentEnd = sequencerNodeToPlay;
      const nodeSchedulingInfo = this._getSchedulingNodeInfoForSequencerNode(
        sequencerNodeToPlay
      )
      if (nodeSchedulingInfo) {
        if (
          !Utils.getInstance().isWrapSequencerNodeInstance(
            sequencerNodeToPlay
          )
        ) {
          nodeDuration =  nodeSchedulingInfo.getDuration() +
          nodeSchedulingInfo.getRelativeStartTime() +
          nodeSchedulingInfo.getRelativeEndTime();
          totalBufferDurationInMilliseconds =
            totalBufferDurationInMilliseconds +
            nodeSchedulingInfo.getDuration() +
            nodeSchedulingInfo.getRelativeStartTime() +
            nodeSchedulingInfo.getRelativeEndTime()
        }
      }
      if (
        Utils.getInstance().isWrapSequencerNodeInstance(sequencerNodeToPlay)
      ) {
        const marginInMilliseconds = sequencerNodeToPlay.getCurrentNodeMargin()
        if (marginInMilliseconds > 0) {
          nodeDuration = nodeDuration + marginInMilliseconds;
          totalBufferDurationInMilliseconds =
            totalBufferDurationInMilliseconds + marginInMilliseconds
        }
      }

      totalSegmentDurationInMilliseconds = totalSegmentDurationInMilliseconds + nodeDuration;
      if(totalSegmentDurationInMilliseconds > maxDurationInMilliseconds){
        sequencerNodeSegmentEnd = sequencerNodeToPlay;
        arrayOSegmentsToReturn.push([sequencerNodeSegmentStart, sequencerNodeSegmentEnd, segmentStartTimestampInMilliseconds, segmentStartTimestampInMilliseconds+ totalSegmentDurationInMilliseconds ]);
        segmentStartTimestampInMilliseconds = segmentStartTimestampInMilliseconds+ totalSegmentDurationInMilliseconds;
        totalSegmentDurationInMilliseconds =  0;
        sequencerNodeSegmentStart = null;
        sequencerNodeSegmentEnd = null;
      }

      if (sequencerNodeToPlay === sequencerNodeToStopPlayAt) {
        sequencerNodeToPlay = null;
      }
      if (sequencerNodeToPlay) {
        sequencerNodeToPlay = sequencerNodeToPlay.getNext();
        if(!sequencerNodeSegmentStart){
          sequencerNodeSegmentStart = sequencerNodeToPlay;
        }
      }
    }
    arrayOSegmentsToReturn.push([sequencerNodeSegmentStart, sequencerNodeSegmentEnd, segmentStartTimestampInMilliseconds, totalSegmentDurationInMilliseconds]);
    return arrayOSegmentsToReturn;
  },

  _calculateDurationInMilliseconds: function (
    sequencerNodeToStartFrom,
    stopAfterMilliseconds,
    sequencerNodeToStopPlayAt,
    includeDeletedSegmentsWhenPossible
  ) {
    if (!sequencerNodeToStartFrom) {
      sequencerNodeToStartFrom = this.getSequencer().getFirstNode()
    }
    let totalBufferDurationInMilliseconds = 0
    let sequencerNodeToPlay = sequencerNodeToStartFrom
    while (
      sequencerNodeToPlay &&
      (!stopAfterMilliseconds ||
        totalBufferDurationInMilliseconds < stopAfterMilliseconds)
    ) {
      const nodeSchedulingInfo = this._getSchedulingNodeInfoForSequencerNode(sequencerNodeToPlay)
      if (nodeSchedulingInfo) {
        if (
          !Utils.getInstance().isWrapSequencerNodeInstance(
            sequencerNodeToPlay
          )
        ) {
          totalBufferDurationInMilliseconds =
            totalBufferDurationInMilliseconds +
            nodeSchedulingInfo.getDuration() +
            nodeSchedulingInfo.getRelativeStartTime() +
            nodeSchedulingInfo.getRelativeEndTime()
        }
      }
      if (
        Utils.getInstance().isWrapSequencerNodeInstance(sequencerNodeToPlay)
      ) {
        const marginInMilliseconds = sequencerNodeToPlay.getCurrentNodeMargin()
        if (marginInMilliseconds > 0) {
          totalBufferDurationInMilliseconds =
            totalBufferDurationInMilliseconds + marginInMilliseconds
        }
      }

      if (sequencerNodeToPlay === sequencerNodeToStopPlayAt) {
        sequencerNodeToPlay = null
      }
      if(includeDeletedSegmentsWhenPossible && sequencerNodeToPlay){
        let foundNextNodeBecauseOfBridgeGap = false;
        if(!foundNextNodeBecauseOfBridgeGap && this._nodeCidToNextNonRetainedDeletedSequencerNode[sequencerNodeToPlay.cid]){
          sequencerNodeToPlay = this._nodeCidToNextNonRetainedDeletedSequencerNode[sequencerNodeToPlay.cid];
          foundNextNodeBecauseOfBridgeGap = true;
        }
        if(!foundNextNodeBecauseOfBridgeGap && Utils.getInstance().isNonRetainedDeletedSequencerNode(sequencerNodeToPlay) && this._nonRetainedDeletedSequencerNodeCidToNextNode[sequencerNodeToPlay.cid]){
          sequencerNodeToPlay = this._nonRetainedDeletedSequencerNodeCidToNextNode[sequencerNodeToPlay.cid];
          foundNextNodeBecauseOfBridgeGap = true;
        }
        if(!foundNextNodeBecauseOfBridgeGap){
          sequencerNodeToPlay = sequencerNodeToPlay.getNext()
        }
      }else{
        if (sequencerNodeToPlay) {
          sequencerNodeToPlay = sequencerNodeToPlay.getNext()
        }
      }
    }
    return totalBufferDurationInMilliseconds
  },

  _createMergeNodesForPlayback: function (
    sequencerNodeToStartFrom,
    stopAfterMilliseconds,
    sequencerNodeToStopPlayAt,
    audioContext,
    shouldSequencerNodeBePlayedValidatorFunction,
    includeDeletedSegmentsWhenPossible
  ) {
    if (!sequencerNodeToStartFrom) {
      sequencerNodeToStartFrom = this.getSequencer().getFirstNode()
    }
    if(Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNodeToStartFrom)){
      sequencerNodeToStartFrom =  sequencerNodeToStartFrom.getNextNodeMatchingCondition((function(nextNode){
        return !Utils.getInstance().isDeletedSequencerNodeInstance(nextNode)
      }).bind(this));
    }
    let totalPlaybackDurationInMilliseconds = 0
    let sequencerNodeToPlay = sequencerNodeToStartFrom
    const mergedNodes = []
    let lastMergedNodeCreated = null
    this._createWrapNodesThatStartedBeforeNode(
      sequencerNodeToStartFrom,
      mergedNodes,
      audioContext
    )
    while (
      sequencerNodeToPlay &&
      (!stopAfterMilliseconds ||
        totalPlaybackDurationInMilliseconds < stopAfterMilliseconds)
    ) {
      const nodeSchedulingInfo = this._getSchedulingNodeInfoForSequencerNode(
        sequencerNodeToPlay
      )
      const shouldBePlayed = shouldSequencerNodeBePlayedValidatorFunction? shouldSequencerNodeBePlayedValidatorFunction(sequencerNodeToPlay, nodeSchedulingInfo): true;
      if(shouldBePlayed){
        if (nodeSchedulingInfo) {
          lastMergedNodeCreated = this._createOrAddToMergeNodes(
            lastMergedNodeCreated,
            nodeSchedulingInfo,
            mergedNodes,
            audioContext
          )
          if (
            !Utils.getInstance().isWrapSequencerNodeInstance(
              sequencerNodeToPlay
            )
          ) {
            totalPlaybackDurationInMilliseconds =
              totalPlaybackDurationInMilliseconds +
              nodeSchedulingInfo.getDuration() +
              nodeSchedulingInfo.getRelativeStartTime() +
              nodeSchedulingInfo.getRelativeEndTime()
          }
        }
        if (
          Utils.getInstance().isWrapSequencerNodeInstance(sequencerNodeToPlay)
        ) {
          lastMergedNodeCreated = null
          const marginInMilliseconds = sequencerNodeToPlay.getCurrentNodeMargin()
          if (marginInMilliseconds > 0) {
            totalPlaybackDurationInMilliseconds =
              totalPlaybackDurationInMilliseconds + marginInMilliseconds
          }
        }
      }

      if (sequencerNodeToPlay === sequencerNodeToStopPlayAt) {
        sequencerNodeToPlay = null
      }
      if (sequencerNodeToPlay) {
        
        if(includeDeletedSegmentsWhenPossible){
          let foundNextNodeBecauseOfBridgeGap = false;
          if(!foundNextNodeBecauseOfBridgeGap && this._nodeCidToNextNonRetainedDeletedSequencerNode[sequencerNodeToPlay.cid]){
            sequencerNodeToPlay = this._nodeCidToNextNonRetainedDeletedSequencerNode[sequencerNodeToPlay.cid];
            foundNextNodeBecauseOfBridgeGap = true;
          }
          if(!foundNextNodeBecauseOfBridgeGap && Utils.getInstance().isNonRetainedDeletedSequencerNode(sequencerNodeToPlay) && this._nonRetainedDeletedSequencerNodeCidToNextNode[sequencerNodeToPlay.cid]){
            sequencerNodeToPlay = this._nonRetainedDeletedSequencerNodeCidToNextNode[sequencerNodeToPlay.cid];
            foundNextNodeBecauseOfBridgeGap = true;
          }
          if(!foundNextNodeBecauseOfBridgeGap){
            sequencerNodeToPlay = sequencerNodeToPlay.getNext()
          }
        }else{
          sequencerNodeToPlay = sequencerNodeToPlay.getNext()
        }
      }
    }
    mergedNodes.totalPlaybackDurationInMilliseconds = totalPlaybackDurationInMilliseconds
    return mergedNodes
  },

  _getTotalWrapNodeDurationInMilliseconds: function (wrapNode) {
    const audioSegment = wrapNode.getAudioSegment()
    if (
      !audioSegment ||
      !wrapNode.isCreatingAudioSegmentThatWillBeMergeWithWrappedNodeAudioSegment()
    ) {
      return 0
    }

    const durationInMilliseconds = 0

    const promisesOfNodeThatNeedToBerenderedFirst = []
    let nodeWrapped = wrapNode.getStartWrappedNode()
    const audioUrl = audioSegment.getAudioUrl()
    const trimStart = audioSegment.getStartTime()
    const trimEnd = audioSegment.getEndTime()
    let totalDurationInMillisecondsOfContainedWrapNodes = 0
    let totalDurationInMillisecondsOfWrappedNodes = 0
    while (nodeWrapped) {
      if (Utils.getInstance().isWrapSequencerNodeInstance(nodeWrapped)) {
        totalDurationInMillisecondsOfContainedWrapNodes =
          totalDurationInMillisecondsOfContainedWrapNodes +
          nodeWrapped.getCurrentNodeMargin()
      } else {
        totalDurationInMillisecondsOfWrappedNodes =
          totalDurationInMillisecondsOfWrappedNodes +
          this._getDefaultNetDurationWithoutCrossfadeInMilliseconds(
            nodeWrapped
          )
      }
      if (nodeWrapped == wrapNode.getEndWrappedNode()) {
        nodeWrapped = null
      } else {
        nodeWrapped = nodeWrapped.getNext()
      }
    }

    const startPaddingInMilliseconds = wrapNode.getIntroPaddingInMilliseconds()
    const endPaddingInMilliseconds = wrapNode.getOutroPaddingInMilliseconds()
    return (
      startPaddingInMilliseconds +
      totalDurationInMillisecondsOfWrappedNodes +
      endPaddingInMilliseconds +
      totalDurationInMillisecondsOfContainedWrapNodes
    )
  },

  _createAllSequencerNodesSchedulingInfo: function (shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible) {
    let sequencerNodeToCalculate = this.getSequencer().getFirstNode()
    let totalBufferDurationInMilliseconds = 0
    const SPACE_BETWEEN_WORD_DURING_PLAYBACK = 0 //This should be used during testing only
    while (sequencerNodeToCalculate) {
      if(!shouldSequencerNodeBePlayedValidatorFunction ||(shouldSequencerNodeBePlayedValidatorFunction && shouldSequencerNodeBePlayedValidatorFunction(sequencerNodeToCalculate))){
        if (
          Utils.getInstance().isWrapSequencerNodeInstance(
            sequencerNodeToCalculate
          )
        ) {
          if (
            Utils.getInstance().isStartMusicWrapSequencerNodeInstance(
              sequencerNodeToCalculate
            )
          ) {
            const durationInMilliseconds = this._getTotalWrapNodeDurationInMilliseconds(
              sequencerNodeToCalculate
            )
            if (durationInMilliseconds > 0) {
              const nodeSchedulingInfo = this._createDefaultNodeSchedulingInfo(
                sequencerNodeToCalculate,
                totalBufferDurationInMilliseconds,
                durationInMilliseconds,
                includeDeletedSegmentsWhenPossible
              )
            }
          }
          const marginInMilliseconds = sequencerNodeToCalculate.getCurrentNodeMargin()
          if (marginInMilliseconds > 0) {
            totalBufferDurationInMilliseconds =
              totalBufferDurationInMilliseconds +
              marginInMilliseconds +
              SPACE_BETWEEN_WORD_DURING_PLAYBACK
          }
        } else {
          if (
            Utils.getInstance().isWordSequencerNodeInstance(
              sequencerNodeToCalculate
            ) ||
            Utils.getInstance().isPauseSequencerNodeInstance(
              sequencerNodeToCalculate
            )
          ) {
            const nodeSchedulingInfo = this._createDefaultNodeSchedulingInfo(
              sequencerNodeToCalculate,
              totalBufferDurationInMilliseconds,
              null,
              includeDeletedSegmentsWhenPossible
            )
            totalBufferDurationInMilliseconds =
              totalBufferDurationInMilliseconds +
              nodeSchedulingInfo.getDuration() +
              nodeSchedulingInfo.getRelativeStartTime() +
              nodeSchedulingInfo.getRelativeEndTime() +
              SPACE_BETWEEN_WORD_DURING_PLAYBACK;

              
              if(includeDeletedSegmentsWhenPossible){
                //If the next node word
                //const nextNodeIsAWordOrPause = sequencerNodeToCalculate.getNext() && (Utils.getInstance().isWordSequencerNodeInstance(sequencerNodeToCalculate.getNext()) || Utils.getInstance().isPauseSequencerNodeInstance(sequencerNodeToCalculate.getNext()))
                //if(nextNodeIsAWordOrPause && sequencerNodeToCalculate.getNext() &&  !sequencerNodeToCalculate.getNext().isThereContinuityOnLeft() && Utils.getInstance().areNodesConsecutiveAndSameFile(sequencerNodeToCalculate, sequencerNodeToCalculate.getNext())){
                if(Utils.getInstance().canCreateNonRetainedDeletedNodeAfter(sequencerNodeToCalculate)){
                  const nonRetainedDeletedSequencerNode =  Utils.getInstance().createNonRetainedDeletedSequencerNode(sequencerNodeToCalculate.getAudioSegment().getAudioUrl());
                  this._nodeCidToNextNonRetainedDeletedSequencerNode[sequencerNodeToCalculate.cid] = nonRetainedDeletedSequencerNode;
                  this._nonRetainedDeletedSequencerNodeCidToNextNode[nonRetainedDeletedSequencerNode.cid] = sequencerNodeToCalculate.getNext() ;
                  const nodeSchedulingInfo = this._createDefaultNodeSchedulingInfoFromDeletedNode( nonRetainedDeletedSequencerNode, totalBufferDurationInMilliseconds, null, sequencerNodeToCalculate, sequencerNodeToCalculate.getNext(), includeDeletedSegmentsWhenPossible);
                  totalBufferDurationInMilliseconds =
                  totalBufferDurationInMilliseconds +
                  nodeSchedulingInfo.getDuration() +
                  nodeSchedulingInfo.getRelativeStartTime() +
                  nodeSchedulingInfo.getRelativeEndTime() +
                  SPACE_BETWEEN_WORD_DURING_PLAYBACK
                }
              }
          } else {
            if (Utils.getInstance().isAudioSequencerNodeInstance(sequencerNodeToCalculate) || Utils.getInstance().isVideoSequencerNodeInstance(sequencerNodeToCalculate)) {
              const nodeSchedulingInfo = this._createDefaultNodeSchedulingInfo(
                sequencerNodeToCalculate,
                totalBufferDurationInMilliseconds,
                null,
                includeDeletedSegmentsWhenPossible
              )
              totalBufferDurationInMilliseconds =
                totalBufferDurationInMilliseconds +
                nodeSchedulingInfo.getDuration() +
                nodeSchedulingInfo.getRelativeStartTime() +
                nodeSchedulingInfo.getRelativeEndTime() +
                SPACE_BETWEEN_WORD_DURING_PLAYBACK
            }else{
              if(includeDeletedSegmentsWhenPossible && Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNodeToCalculate) && Utils.getInstance().canDeletedNodeBeBridge(sequencerNodeToCalculate)){
                const nodeSchedulingInfoxxx = this._createDefaultNodeSchedulingInfoFromDeletedNode( sequencerNodeToCalculate, totalBufferDurationInMilliseconds, null, null,null, includeDeletedSegmentsWhenPossible);
                totalBufferDurationInMilliseconds =
                totalBufferDurationInMilliseconds +
                nodeSchedulingInfoxxx.getDuration() +
                nodeSchedulingInfoxxx.getRelativeStartTime() +
                nodeSchedulingInfoxxx.getRelativeEndTime() +
                SPACE_BETWEEN_WORD_DURING_PLAYBACK
                //Finding the next word or pause node
                const isSequencerNodeIsPauseOrWordFunction = (function(node){
                  //Check if the node is a pause or a word
                  return !!node.getAudioSegment() && node.getAudioSegment().getTranscribedAudioInstance();
                })
                const nextWordOrPauseNode = sequencerNodeToCalculate.getNextNodeMatchingCondition(isSequencerNodeIsPauseOrWordFunction.bind(sequencerNodeToCalculate));
                if(nextWordOrPauseNode && nextWordOrPauseNode.getPrevious()){
                  sequencerNodeToCalculate = nextWordOrPauseNode.getPrevious()//Reassign the previous before the next word or pause so that it skips all the deleted nodes
                }
              }
            }
          }
        }
      }
      sequencerNodeToCalculate = sequencerNodeToCalculate.getNext()
    }
    this._totalBufferDurationInMilliseconds = totalBufferDurationInMilliseconds
  },

  _createOrAddToMergeNodes: function (
    lastMergedNodeCreated,
    nodeSchedulingInfo,
    mergedNodeSchedulingInfoArray,
    audioContext
  ) {
    if (
      Utils.getInstance().isWordSequencerNodeInstance(
        nodeSchedulingInfo.getSequencerNodeReference()
      ) ||
      Utils.getInstance().isPauseSequencerNodeInstance(
        nodeSchedulingInfo.getSequencerNodeReference()
      )||
      Utils.getInstance().isDeletedSequencerNodeInstance(
        nodeSchedulingInfo.getSequencerNodeReference()
      )||
      Utils.getInstance().isNonRetainedDeletedSequencerNode(
        nodeSchedulingInfo.getSequencerNodeReference()
      )
    ) {
      if (!lastMergedNodeCreated) {
        lastMergedNodeCreated = new MergedSequencerNodeSchedulingInfo({sequencer: this.getSequencer()})
        if (lastMergedNodeCreated.merge(nodeSchedulingInfo, audioContext)) {
          mergedNodeSchedulingInfoArray.push(lastMergedNodeCreated)
        } else {
          lastMergedNodeCreated = null
          mergedNodeSchedulingInfoArray.push(nodeSchedulingInfo)
        }
      } else {
        if (!lastMergedNodeCreated.merge(nodeSchedulingInfo, audioContext)) {
          lastMergedNodeCreated = new MergedSequencerNodeSchedulingInfo({sequencer: this.getSequencer()})
          if (lastMergedNodeCreated.merge(nodeSchedulingInfo, audioContext)) {
            mergedNodeSchedulingInfoArray.push(lastMergedNodeCreated)
          } else {
            lastMergedNodeCreated = null
            mergedNodeSchedulingInfoArray.push(nodeSchedulingInfo)
          }
        }
      }
    } else {
      if (
        Utils.getInstance().isStartMusicWrapSequencerNodeInstance(
          nodeSchedulingInfo.getSequencerNodeReference()
        ) ||
        Utils.getInstance().isEndMusicWrapSequencerNodeInstance(
          nodeSchedulingInfo.getSequencerNodeReference()
        ) ||
        Utils.getInstance().isAudioSequencerNodeInstance(
          nodeSchedulingInfo.getSequencerNodeReference()
        ) ||
        Utils.getInstance().isVideoSequencerNodeInstance(
          nodeSchedulingInfo.getSequencerNodeReference()
        )
      ) {
        lastMergedNodeCreated = null
        mergedNodeSchedulingInfoArray.push(
          nodeSchedulingInfo.cloneWithNewContext(audioContext)
        )
      }
    }
    return lastMergedNodeCreated
  },

  _createDefaultNodeSchedulingInfo: function (
    sequencerNode,
    currentTotalDurationInMilliseconds,
    nodeDurationInMilliseconds,
    includeDeletedSegmentsWhenPossible
  ) {
    const audioSegment = sequencerNode.getAudioSegment()
    if (audioSegment) {
      const audioUrl = audioSegment.getAudioUrl()
      if (audioUrl) {
        const isSpecificSectionOfAudioBufferSelected = audioSegment.getEndTime()
        const audioType = this._getAudioType(sequencerNode)
        const sourceAudioBuffer = this._getAudioBufferForAudioUrlFromCache(
          audioUrl,
          audioType
        )
        const nodeSchedulingInfo = new SequencerNodeSchedulingInfo({sequencer: this.getSequencer()})

        const audioNode = this.getAudioContext().createBufferSource()
        const crossfadeDuratioInMillisecondAtEnd = includeDeletedSegmentsWhenPossible? 0:
          this._getCrossafeInMillisecondsAtTheEnd(sequencerNode) * -1
        const crossfadeDuratioInMillisecondAtBeginning = includeDeletedSegmentsWhenPossible? 0:
          this._getCrossafeInMillisecondsAtTheBeginnnig(sequencerNode) * -1
          let startTimeOffsetForSourceBuffer =
          (audioSegment.getStartTime() ? audioSegment.getStartTime() : 0) +
          crossfadeDuratioInMillisecondAtBeginning
        if (startTimeOffsetForSourceBuffer < 0) {
          startTimeOffsetForSourceBuffer = 0
        }
        let endTimeOffsetForSourceBuffer =
          (audioSegment.getEndTime()
            ? audioSegment.getEndTime()
            : AudioBufferHelper.getInstance().getMaxAudioBufferDuration(
                sourceAudioBuffer
              ) * 1000) - crossfadeDuratioInMillisecondAtEnd
        const maxAudioBufferDuration = AudioBufferHelper.getInstance().getMaxAudioBufferDuration(
          sourceAudioBuffer
        )
        if (endTimeOffsetForSourceBuffer > maxAudioBufferDuration * 1000) {
          endTimeOffsetForSourceBuffer = maxAudioBufferDuration * 1000
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              'SequencerRendererFileRendering',
              'audioBufferDurationMightBeWrong',
              'Audio buffer duration might be wrong'
            )
          }
        }
        audioNode.buffer = sourceAudioBuffer
        nodeSchedulingInfo.setAudioNode(audioNode)
        nodeSchedulingInfo.setSequencerNodeReference(sequencerNode)
        nodeSchedulingInfo.setRelativeStartTime(
          crossfadeDuratioInMillisecondAtBeginning
        )
        nodeSchedulingInfo.setRelativeEndTime(
          crossfadeDuratioInMillisecondAtEnd
        )
        nodeSchedulingInfo.setStartTimeOffset(
          currentTotalDurationInMilliseconds +
            crossfadeDuratioInMillisecondAtBeginning
        )
        nodeSchedulingInfo.setStartTimeOffsetForSourceBuffer(
          startTimeOffsetForSourceBuffer
        )
        nodeSchedulingInfo.setEndTimeOffsetForSourceBuffer(
          endTimeOffsetForSourceBuffer
        )
        nodeSchedulingInfo.setDuration(nodeDurationInMilliseconds)
        nodeSchedulingInfo.setAudioBufferPlaybackDuration(
          nodeDurationInMilliseconds
        )
        nodeSchedulingInfo.setStartTimeOffsetLoopForSourceBuffer(0)
        if (
          Utils.getInstance().isStartMusicWrapSequencerNodeInstance(
            sequencerNode
          ) ||
          Utils.getInstance().isEndMusicWrapSequencerNodeInstance(
            sequencerNode
          )
        ) {
          if (sequencerNode.getLoopToMatchWrapNodeDuration()) {
            audioNode.loop = true
            audioNode.loopStart =
              nodeSchedulingInfo.getStartTimeOffsetForSourceBuffer() / 1000
            audioNode.loopEnd =
              nodeSchedulingInfo.getEndTimeOffsetForSourceBuffer() / 1000
          } else {
            nodeSchedulingInfo.setAudioBufferPlaybackDuration(
              nodeSchedulingInfo.getEndTimeOffsetForSourceBuffer() -
                nodeSchedulingInfo.getStartTimeOffsetForSourceBuffer()
            )
          }
        }
        this._nodeCidToNodeSchedulingInfo[
          sequencerNode.cid
        ] = nodeSchedulingInfo
        let arrayOfNodeSchedulingInfos = this
          ._startTimeInMillisecondsToNodeSchedulingInfos[
          nodeSchedulingInfo.getStartTimeOffset()
        ]
        if (!arrayOfNodeSchedulingInfos) {
          arrayOfNodeSchedulingInfos = []
          this._startTimeInMillisecondsToNodeSchedulingInfos[
            nodeSchedulingInfo.getStartTimeOffset()
          ] = arrayOfNodeSchedulingInfos
        }
        arrayOfNodeSchedulingInfos.push(nodeSchedulingInfo)
        return nodeSchedulingInfo
      }
    }
  },
  
  _createDefaultNodeSchedulingInfoFromDeletedNode: function (
    deletedSequencerNode,
    currentTotalDurationInMilliseconds,
    nodeDurationInMilliseconds,
    previousNode,
    nextNode,
    includeDeletedSegmentsWhenPossible
  ) {
    const isSequencerNodeIsPauseOrWordFunction = (function(node){
      //Check if the node is a pause or a word
      return !!node.getAudioSegment() && node.getAudioSegment().getTranscribedAudioInstance();
    })
    previousNode = previousNode? previousNode: deletedSequencerNode.getPreviousNodeMatchingCondition(isSequencerNodeIsPauseOrWordFunction.bind(deletedSequencerNode), 0 , true);
    nextNode = nextNode? nextNode: deletedSequencerNode.getNextNodeMatchingCondition(isSequencerNodeIsPauseOrWordFunction.bind(deletedSequencerNode), 0 , true);
    
    if ((previousNode && nextNode )|| Utils.getInstance().areNodesConsecutiveAndSameFile(previousNode, nextNode)) {
 
    
    const previousNodeAudioSegment = previousNode.getAudioSegment();
    const nextNodeAudioSegment = nextNode.getAudioSegment();
      const audioUrl = previousNodeAudioSegment? previousNodeAudioSegment.getAudioUrl(): nextNodeAudioSegment.getAudioUrl();
      if (audioUrl) {
        const audioType = this._getAudioType(previousNode)
        const sourceAudioBuffer = this._getAudioBufferForAudioUrlFromCache(
          audioUrl,
          audioType
        )
        const nodeSchedulingInfo = new SequencerNodeSchedulingInfo({sequencer: this.getSequencer()})

        const audioNode = this.getAudioContext().createBufferSource()
        const crossfadeDuratioInMillisecondAtEnd = includeDeletedSegmentsWhenPossible? 0:
          this._getCrossafeInMillisecondsAtTheEnd(previousNode) * -1
        const crossfadeDuratioInMillisecondAtBeginning = includeDeletedSegmentsWhenPossible? 0:
          this._getCrossafeInMillisecondsAtTheBeginnnig(nextNode) * -1
          let startTimeOffsetForSourceBuffer =
          previousNodeAudioSegment.getEndTime() -
          crossfadeDuratioInMillisecondAtBeginning
        if (startTimeOffsetForSourceBuffer < 0) {
          startTimeOffsetForSourceBuffer = 0
        }
        let endTimeOffsetForSourceBuffer =
        nextNodeAudioSegment.getStartTime() + crossfadeDuratioInMillisecondAtEnd
        const maxAudioBufferDuration = AudioBufferHelper.getInstance().getMaxAudioBufferDuration(
          sourceAudioBuffer
        )
        if (endTimeOffsetForSourceBuffer > maxAudioBufferDuration * 1000) {
          endTimeOffsetForSourceBuffer = maxAudioBufferDuration * 1000
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              'SequencerRendererFileRendering',
              'audioBufferDurationMightBeWrong',
              'Audio buffer duration might be wrong'
            )
          }
        }
        audioNode.buffer = sourceAudioBuffer
        nodeSchedulingInfo.setAudioNode(audioNode)
        nodeSchedulingInfo.setSequencerNodeReference(deletedSequencerNode)
        nodeSchedulingInfo.setRelativeStartTime(
          crossfadeDuratioInMillisecondAtBeginning
        )
        nodeSchedulingInfo.setRelativeEndTime(
          crossfadeDuratioInMillisecondAtEnd
        )
        nodeSchedulingInfo.setStartTimeOffset(
          currentTotalDurationInMilliseconds +
            crossfadeDuratioInMillisecondAtBeginning
        )
        nodeSchedulingInfo.setStartTimeOffsetForSourceBuffer(
          startTimeOffsetForSourceBuffer
        )
        nodeSchedulingInfo.setEndTimeOffsetForSourceBuffer(
          endTimeOffsetForSourceBuffer
        )
        nodeDurationInMilliseconds = endTimeOffsetForSourceBuffer - startTimeOffsetForSourceBuffer;
        nodeSchedulingInfo.setDuration(nodeDurationInMilliseconds)
        nodeSchedulingInfo.setAudioBufferPlaybackDuration(
          nodeDurationInMilliseconds
        )
        nodeSchedulingInfo.setStartTimeOffsetLoopForSourceBuffer(0)
      
        this._nodeCidToNodeSchedulingInfo[
          deletedSequencerNode.cid
        ] = nodeSchedulingInfo
        let arrayOfNodeSchedulingInfos = this
          ._startTimeInMillisecondsToNodeSchedulingInfos[
          nodeSchedulingInfo.getStartTimeOffset()
        ]
        if (!arrayOfNodeSchedulingInfos) {
          arrayOfNodeSchedulingInfos = []
          this._startTimeInMillisecondsToNodeSchedulingInfos[
            nodeSchedulingInfo.getStartTimeOffset()
          ] = arrayOfNodeSchedulingInfos
        }
        arrayOfNodeSchedulingInfos.push(nodeSchedulingInfo)
        return nodeSchedulingInfo
      }
    }
  },

  getSrtText : function(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, asVtt, doNotIncludeSpeaker, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){

      this.stopPlayingRenderedBuffer()
      this.set('playbackStopped', false)
      this.getSequencer().setRenderingBeingCreated(true)
      let offlineCtx = null;
  
      if (progressReportFunction) {
        progressReportFunction(null,window.getI18n(ti18n, 'GENERATING_TRANSCRIPT_FILE'))
      }
     
      if (window.trebbleAnalyticsHelper) {
        window.trebbleAnalyticsHelper.trackEvent(
          'SequencerRendererSrtTextRendering',
          'SrtFileRenderingStarted',
          'Srt file rendering started'
        )
      }
      let finalBufferDurationInMilliseconds = 0;
      return this.renderSequencer(true, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then(
          function () {
            finalBufferDurationInMilliseconds = this._calculateDurationInMilliseconds(sequencerNodeToStartFrom,null,sequencerNodeToEndOn, includeDeletedSegmentsWhenPossible)
            offlineCtx = new OfflineAudioContext(this._maxNumberOfChannels, this.getAudioContext().sampleRate * (finalBufferDurationInMilliseconds / 1000), this.getAudioContext().sampleRate)
            return this._scheduleAudioNodeToContext(sequencerNodeToStartFrom, null, sequencerNodeToEndOn, null, offlineCtx, null, null, null, true, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible)
          }.bind(this)
        ).then(
           (mergedNodeSchedulingArray) =>{
            return this.getSrtTextForSelectedNodes(asVtt, mergedNodeSchedulingArray, doNotIncludeSpeaker);
          }).then(function(srtTextContent){
            if (window.trebbleAnalyticsHelper) {
              window.trebbleAnalyticsHelper.trackEvent(
                'SequencerRendererFileRendering',
                'MediaFileRenderingCompleted',
                'Media file rendering completed'
              )
            }
            return srtTextContent;
          }).catch(function(error){
            console.error('Failed to create .srt file. Error:' + error)
            if (window.trebbleAnalyticsHelper) {
              window.trebbleAnalyticsHelper.trackEvent(
                'SequencerRendererFileRendering',
                'FailedToCreateSrtFile',
                'Failed to create Srt file',
                null,
                error
              )
            }
            throw error;
          });
  },

  getTranscriptionAndEditsInfo : function(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){

    this.stopPlayingRenderedBuffer()
    this.set('playbackStopped', false)
    this.getSequencer().setRenderingBeingCreated(true)
    let offlineCtx = null;

    if (progressReportFunction) {
      progressReportFunction(null,window.getI18n(ti18n, 'GENERATING_TRANSCRIPT_FILE'))
    }
   
    if (window.trebbleAnalyticsHelper) {
      window.trebbleAnalyticsHelper.trackEvent(
        'SequencerRendererSrtTextRendering',
        'SrtFileRenderingStarted',
        'Srt file rendering started'
      )
    }
    let finalBufferDurationInMilliseconds = 0;
    return this.renderSequencer(true, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then(
        function () {
          finalBufferDurationInMilliseconds = this._calculateDurationInMilliseconds(sequencerNodeToStartFrom,null,sequencerNodeToEndOn, includeDeletedSegmentsWhenPossible)
          offlineCtx = new OfflineAudioContext(this._maxNumberOfChannels, this.getAudioContext().sampleRate * (finalBufferDurationInMilliseconds / 1000), this.getAudioContext().sampleRate)
          return this._scheduleAudioNodeToContext(sequencerNodeToStartFrom, null, sequencerNodeToEndOn, null, offlineCtx, null, null, null, true, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible)
        }.bind(this)
      ).then(
         (mergedNodeSchedulingArray) =>{
          return this.getTranscriptionAndEditsInfoForSchedulingNodes(mergedNodeSchedulingArray,finalBufferDurationInMilliseconds, includeDeletedSegmentsWhenPossible);
        })
},

  getSrtTextForSelectedNodes: function (asVtt, nodeSchedulingArray, doNotIncludeSpeaker) {
    try {
      let textContent = ''

      let convertedOutput = asVtt ? 'WEBVTT\n\n' : ''
      let subtitleIndex = 1
      let duration = 0
      let current_start = null
      let nextline = ''
      let changingSpeaker = false;
      let currentSpeaker = null;
      const MAX_NUMBER_OF_CHAR_PER_LINE = 32;
      const MAX_NUMBER_OF_LINES =2;
      let currentNumberOfLines = 0;
      let nextWordSequencerNode = null;
      let nextWord = null;
      let nextWordSpeaker = null;
      let allLines = '';
      const UNKNOWN_SPEAKER_LABEL = "Unknown";


     
      let startTime = null
      const wordNodeSchedulingInfoArray = []

      const sortedNodeSchedulingArray = nodeSchedulingArray.sort(function (
        nodeA,
        nodeB
      ) {
        return nodeA.getStartTimeOffset() - nodeB.getStartTimeOffset()
      })
      startTime = sortedNodeSchedulingArray[0].getStartTimeOffset()
      sortedNodeSchedulingArray.map(function (nodeSchedulingInfo) {
        if (nodeSchedulingInfo._getNodeSchedulingInfoMergedArray) {
          nodeSchedulingInfo
            ._getNodeSchedulingInfoMergedArray()
            .map(function (subNodeSchedulingInfo) {
              if (
                subNodeSchedulingInfo.getSequencerNodeReference() &&
                Utils.getInstance().isWordSequencerNodeInstance(
                  subNodeSchedulingInfo.getSequencerNodeReference()
                )
              ) {
                wordNodeSchedulingInfoArray.push(subNodeSchedulingInfo)
              }
            })
        } else {
          if (
            nodeSchedulingInfo.getSequencerNodeReference() &&
            Utils.getInstance().isWordSequencerNodeInstance(
              nodeSchedulingInfo.getSequencerNodeReference()
            )
          ) {
            wordNodeSchedulingInfoArray.push(nodeSchedulingInfo)
          }
        }
      })

      const sortedWordNodeSchedulingInfoArray = wordNodeSchedulingInfoArray.sort(
        function (nodeA, nodeB) {
          return nodeA.getStartTimeOffset() - nodeB.getStartTimeOffset()
        }
      )

      const MAX_UTTERANCE_DURATION_IN_MILLISECONDS = 5000
      for (let i = 0; i < sortedWordNodeSchedulingInfoArray.length; i++) {
        const nodeSchedulingInfo = sortedWordNodeSchedulingInfoArray[i]
        const sequencerNode = nodeSchedulingInfo.getSequencerNodeReference()
        let contentEndsWithPunctuation = false

        if (current_start === null) {
          current_start = nodeSchedulingInfo.getStartTimeOffset();
          changingSpeaker = currentSpeaker !== sequencerNode.getAudioSegment().getSpeakerLabel();
          currentSpeaker = sequencerNode.getAudioSegment().getSpeakerLabel();
          if(changingSpeaker && !doNotIncludeSpeaker){
            nextline += `${currentSpeaker || UNKNOWN_SPEAKER_LABEL}: `;
          }
          currentNumberOfLines = 1;
        }
        textContent = sequencerNode.getAudioSegment().getContent();
        nextWordSequencerNode = i + 1 <sortedWordNodeSchedulingInfoArray.length? sortedWordNodeSchedulingInfoArray[i+1].getSequencerNodeReference(): null;
        nextWord = nextWordSequencerNode? nextWordSequencerNode.getAudioSegment().getContent(): "";
        nextWordSpeaker = nextWordSequencerNode? nextWordSequencerNode.getAudioSegment().getSpeakerLabel(): UNKNOWN_SPEAKER_LABEL;
        nextline += textContent + ' '
        contentEndsWithPunctuation = /[.,;:?!]+$/g.test(
          textContent.trim().slice(-1)
        )

        duration =
          nodeSchedulingInfo.getStartTimeOffset() +
          nodeSchedulingInfo.getDuration()
        if (current_start !== null) {
          if(currentNumberOfLines < MAX_NUMBER_OF_LINES && (doNotIncludeSpeaker || currentSpeaker === nextWordSpeaker)){
            if(nextline.trim().length + nextWord.length > MAX_NUMBER_OF_CHAR_PER_LINE){
              allLines += nextline + '\n'
              nextline = asVtt ? '' : ''
              currentNumberOfLines = currentNumberOfLines+ 1;
            }
          }else{
            if (
              (currentSpeaker !== nextWordSpeaker && !doNotIncludeSpeaker) ||
              nextline.trim().length + nextWord.length > MAX_NUMBER_OF_CHAR_PER_LINE ||
              contentEndsWithPunctuation ||
              duration - current_start > MAX_UTTERANCE_DURATION_IN_MILLISECONDS
            ) {
              if (contentEndsWithPunctuation) {
                nextline = nextline.slice(0, -1) //Remove the space before punctuation
              }
              if (!asVtt) {
                convertedOutput += `${subtitleIndex++}\n`
              }
              convertedOutput +=
                Utils.getInstance().convertTimeInSrtFormat(current_start - startTime, asVtt) +
                ' --> ' +
                Utils.getInstance().convertTimeInSrtFormat(duration - startTime, asVtt) +
                '\n'
              convertedOutput += allLines + nextline + '\n\n'
              nextline = asVtt ? '' : ''
              allLines = asVtt ? '' : ''
              current_start = null
            }
          }
        }
      }

      if (current_start && nextline) {
        //last unprocessed line
        if (!asVtt) {
          convertedOutput += `${subtitleIndex++}\n`
        }
        convertedOutput +=
        Utils.getInstance().convertTimeInSrtFormat(current_start - startTime, asVtt) +
          ' --> ' +
          Utils.getInstance().convertTimeInSrtFormat(duration - startTime, asVtt) +
          '\n'
        convertedOutput += nextline
      }
      return convertedOutput
    } catch (error) {
      console.error('Failed to create .srt file. Error:' + error)
      if (window.trebbleAnalyticsHelper) {
        window.trebbleAnalyticsHelper.trackEvent(
          'SequencerRendererFileRendering',
          'FailedToCreateSrtFile',
          'Failed to create Srt file',
          null,
          error
        )
      }
      return null
    }
  },

  getTranscriptionAndEditsInfoForSchedulingNodes : function(nodeSchedulingArray, totalDurationInMilliseconds, includeDeletedSegmentsWhenPossible){
    const transcriptionInfoArray = [];
    const editBoundariesTimestamps = [];
    const deletedRegionsArray = [];
    const sortedNodeSchedulingArray = nodeSchedulingArray.sort(function (
      nodeA,
      nodeB
    ) {
      return nodeA.getStartTimeOffset() - nodeB.getStartTimeOffset()
    })
    let startTime = sortedNodeSchedulingArray[0].getStartTimeOffset()
    sortedNodeSchedulingArray.map((sequencerSchedulingNode)=>{
      if(sequencerSchedulingNode instanceof MergedSequencerNodeSchedulingInfo){
        sequencerSchedulingNode.getSchedulingNodeToBePlayed().map((n, index)=>{
          this.getTranscriptionInfoFromSequencerSchedulingNode(startTime, n, n.getSequencerNodeReference(), includeDeletedSegmentsWhenPossible, transcriptionInfoArray, deletedRegionsArray, editBoundariesTimestamps);
        })
      }else{
        const sequencerNodeReference =  sequencerSchedulingNode.getSequencerNodeReference();
        if(sequencerNodeReference){
          this.getTranscriptionInfoFromSequencerSchedulingNode(startTime, sequencerSchedulingNode, sequencerSchedulingNode.getSequencerNodeReference(), includeDeletedSegmentsWhenPossible, transcriptionInfoArray, deletedRegionsArray, editBoundariesTimestamps);
        }
      }

    })
    return {transcriptionInfoArray, editBoundariesTimestamps, totalDurationInMilliseconds, deletedRegionsArray,  srtText: this.getSrtTextForSelectedNodes(false, nodeSchedulingArray)};
  },

  getTranscriptionInfoFromSequencerSchedulingNode : function(startTimeOffset, sequencerSchedulingNode, sequencerNodeReference, includeDeletedSegmentsWhenPossible, transcriptionInfoArray, deletedRegionsArray, editBoundariesTimestamps, wasDeleted){
      if(sequencerNodeReference){
        const totalCurrentDurationInMs = transcriptionInfoArray.length > 0? transcriptionInfoArray[transcriptionInfoArray.length -1].endTime: 0;
        const infoToAdd = sequencerSchedulingNode? {
          "startTime":sequencerSchedulingNode.getStartTimeOffset() - sequencerSchedulingNode.getRelativeStartTime() - startTimeOffset,
          "endTime":sequencerSchedulingNode.getEndTimeOffset() - sequencerSchedulingNode.getRelativeEndTime() - startTimeOffset
        }:{
          "startTime": totalCurrentDurationInMs,
          "endTime": totalCurrentDurationInMs + (sequencerNodeReference.getAudioSegment().getDuration())
        }
        if(wasDeleted){
          infoToAdd.wasDeleted = true;
        }
        if(!sequencerNodeReference.isThereContinuityOnLeft()){
          infoToAdd.editedOnTheLeft = true;
          editBoundariesTimestamps.push(infoToAdd.startTime);
        }
        if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNodeReference)){
          infoToAdd.type = "word";
          infoToAdd.content = sequencerNodeReference.getContent();
          infoToAdd.duration = infoToAdd.endTime - infoToAdd.startTime;
          infoToAdd.confidence = sequencerNodeReference.getAudioSegment().getConfidence();
          if(sequencerNodeReference.getAudioSegment().getSpeakerInfo() && sequencerNodeReference.getAudioSegment().getSpeakerInfo().getSpeakerId()){
            infoToAdd.speakerId = sequencerNodeReference.getAudioSegment().getSpeakerInfo().getSpeakerId();
          }
          transcriptionInfoArray.push(infoToAdd);
          //return infoToAdd;
        }
        if(Utils.getInstance().isPauseSequencerNodeInstance(sequencerNodeReference)){
          infoToAdd.type = "unrecognized";
          infoToAdd.content = "·"
          infoToAdd.duration = infoToAdd.endTime - infoToAdd.startTime;
          transcriptionInfoArray.push(infoToAdd);
          //return infoToAdd;
        }
        if(includeDeletedSegmentsWhenPossible){
          if(Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNodeReference)){
            infoToAdd.type = "deleted_section";
            infoToAdd.content = sequencerNodeReference.getContent();
            infoToAdd.duration = infoToAdd.endTime - infoToAdd.startTime;
            const audioUrl = Utils.getInstance().getAudioUrlToBridgeGapForDeletedNodeIfApplicable(sequencerNodeReference);
            const referenceNodesArrayUnordered = this.getSequencer().getAllSequencerNodesInRange(audioUrl, sequencerSchedulingNode.getStartTimeOffsetForSourceBuffer() + sequencerSchedulingNode.getRelativeStartTime(), sequencerSchedulingNode.getEndTimeOffsetForSourceBuffer() + sequencerSchedulingNode.getRelativeEndTime(), null, false );
            const referenceNodesArrayOrdered =  referenceNodesArrayUnordered.sort((a, b)=>{return a.getAudioSegment().getStartTime() - b.getAudioSegment().getStartTime()})
            referenceNodesArrayOrdered.map((function(sequencerNode){
              this.getTranscriptionInfoFromSequencerSchedulingNode(startTimeOffset,null, sequencerNode, includeDeletedSegmentsWhenPossible, transcriptionInfoArray, deletedRegionsArray, editBoundariesTimestamps, true);
            }).bind(this))
            deletedRegionsArray.push(infoToAdd);
            //return infoToAdd;
          }
        }
      }
  },

  /*getTranscriptionInfoFromSequencerNode : function(startTimeOffset, sequencerNodeReference, includeDeletedSegmentsWhenPossible, sequencerSchedulingNode, currentTotalDuration){
    if(sequencerNodeReference && sequencerNodeReference.getAudioSegment()){
      const infoToAdd = sequencerSchedulingNode? {
        "startTime":sequencerSchedulingNode.getStartTimeOffset() + sequencerSchedulingNode.getRelativeStartTime() - startTimeOffset,
        "endTime":sequencerSchedulingNode.getEndTimeOffset()+ sequencerSchedulingNode.getRelativeEndTime() - startTimeOffset
      }:{
        "startTime": currentTotalDuration,
        "endTime":  currentTotalDuration +(sequencerNodeReference.getAudioSegment().getEndTime() - sequencerNodeReference.getAudioSegment().getStartTime())
      }
      if(!sequencerNodeReference.isThereContinuityOnLeft()){
        infoToAdd.editedOnTheLeft = true;
      }
      if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNodeReference)){
        infoToAdd.type = "word";
        infoToAdd.content = sequencerNodeReference.getContent();
        infoToAdd.duration = infoToAdd.endTime - infoToAdd.startTime;
        infoToAdd.confidence = sequencerNodeReference.getAudioSegment().getConfidence();
        if(sequencerNodeReference.getAudioSegment().getSpeakerInfo() && sequencerNodeReference.getAudioSegment().getSpeakerInfo().getSpeakerId()){
          infoToAdd.speakerId = sequencerNodeReference.getAudioSegment().getSpeakerInfo().getSpeakerId();
        }
        return [infoToAdd];
      }
      if(Utils.getInstance().isPauseSequencerNodeInstance(sequencerNodeReference)){
        infoToAdd.type = "unrecognized";
        infoToAdd.duration = infoToAdd.endTime - infoToAdd.startTime;
        return [infoToAdd];
      }
      if(includeDeletedSegmentsWhenPossible && sequencerSchedulingNode){
        if(Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNodeReference)){
          infoToAdd.type = "deleted_section";
          infoToAdd.content = sequencerNodeReference.getContent();
          infoToAdd.duration = infoToAdd.endTime - infoToAdd.startTime;
          const referenceNodesArrayUnordered = this.getSequencer().getAllSequencerNodesInRange(sequencerSchedulingNode.getAudioSegmentMediaUrl(), infoToAdd.startTime, infoToAdd.endTime, null, true );
          const referenceNodesArrayOrdered =  referenceNodesArrayUnordered.sort((a, b)=>{return a.getAudioSegment().getStartTime() - b.getAudioSegment().getStartTime()})
          infoToAdd.deleted_transription_node_info_array = referenceNodesArrayOrdered.reduce((function(acc, sequencerNode){
            const transcriptionInfoArray  = this.getTranscriptionInfoFromSequencerNode(startTimeOffset, sequencerNode, includeDeletedSegmentsWhenPossible, sequencerSchedulingNode, currentTotalDuration);
            
          },[]).bind(this));
          infoToAdd.deleted_content = referenceNodesArrayOrdered.reduce((acc, sequencerNode)=>{if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNode) && sequencerNode.getContent()){return acc +" "+ sequencerNode.getContent();} return acc}, "")
          return infoToAdd;
        }
      }
    }
},*/

  prepareAudioContext: function () {
    return AudioFilterFactory.getInstance().prepareAudioContext(
      this.getAudioContext()
    )
  },

  renderSequencer: function (applyMasterProcessing, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible) {
    this.getSequencer().setRenderingBeingCreated(true)
    this._reinitialize()
    const startTimeOfLoadingAllMasters = new Date().getTime()
    let startTimeCalculatePositions = null
    return this.prepareAudioContext()
      .then(
        function () {
          return this._loadAllMasterAudioBuffersInAudioBufferHelper()
        }.bind(this)
      )
      .then(
        function () {
          console.log(
            'Loading all masters duration ' +
              (new Date().getTime() - startTimeOfLoadingAllMasters)
          )
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackTiming(
              'Loading all masters duration for sequencer renderer',
              new Date().getTime() - startTimeOfLoadingAllMasters
            )
          }
          startTimeCalculatePositions = new Date().getTime()
          return this._createAllSequencerNodesSchedulingInfo(shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible)
        }.bind(this)
      )
      .then(
        function () {
          console.log(
            'Creating node scheduling infos ' +
              (new Date().getTime() - startTimeCalculatePositions)
          )
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackTiming(
              'Creating node scheduling infos for sequencer renderer',
              new Date().getTime() - startTimeCalculatePositions
            )
          }
          this._sequencer.setRenderingBeingCreated(false)
        }.bind(this)
      )
      .catch(
        function (error) {
          this._sequencer.setRenderingBeingCreated(false)
          throw error
        }.bind(this)
      )
  }
})

export default SequencerRendererImpl;
